/**
 * @file leaflet map implementation
 */

// thirdparty imports
import * as L from "leaflet";
import * as hexToRgba from "hex-to-rgba";

// local imports
import { convertToTitleCase } from "../string.utils";

// Create a geojson layer and a map layer
let geojsonLayerGlobal;
let defaultColor = "#2CBD4E";

/**
 * @description for heat map type generation we might use this function, generates colors that are representative of the prop value
 * @param { number } prop - the property to check for value coloration
 * @param { string } hexColorValue - the hex color value
 * @return { string } returns color from basecolor with an alpha that  is representative of the value ( prop )
 */
export const genHeatMap = (prop, hexColorValue) => {
  defaultColor = hexColorValue ? hexColorValue : defaultColor
  const opacity = () => {
    if (prop > 0) {
      return ((prop / 200) + 0.5).toFixed(1);
    } else {
      return 0.5;
    }
  }
  return hexToRgba(defaultColor, opacity());
}

/**
 * @description - Default marker options for point data
 */
export const geojsonMarkerStyle = heatParam => {
  return {
    radius: 8,
    fillColor: heatParam ? genHeatMap(heatParam) : defaultColor,
    color: "#000 ",
    weight: 1,
    opacity: 1,
    fillOpacity: 0.8
  };
};

/**
 * @description - Default marker options for point data when highlighted
 */
export const highlighMarkerStyle = (heatParam = "") => {
  return {
    radius: 15,
    fillColor: heatParam,
    color: "#0000",
    weight: 1,
    opacity: 1,
    fillOpacity: 0.8
  };
};
/**
 * @description - Default feature styles for polygons
 */
export const geoJsonPolygonStyles = (heatParam = "") => {
  return {
    fillColor: heatParam ? genHeatMap(heatParam) : defaultColor,
    fill: heatParam ? genHeatMap(heatParam) : defaultColor,
    fillOpacity: 0.6,
    strokeOpacity: 1
  };
};
const Emitter = {
  events: {},
  on: (type, listener) => {
    Emitter.events[type] = Emitter.events[type] || [];
    Emitter.events[type].push(listener);
  },
  emit: type => {
    if (Emitter.events[type]) {
      Emitter.events[type].map(listener => {
        listener();
      });
    }
  }
};

// Define an eventListener
Emitter.on("mouseout", ({ target }) => {
  target.closePopup();
  this.resetStyle(target);
});

/**
 * @function addGeojsonLayer adds a geojson layer
 * @param { Object } wfs
 * @param { boolean } isPoint
 * @param { Object } style
 * @param { string } popupFields
 * @return { L.geoJSON }
 */
export const addGeojsonLayer = (wfs, isPoint, popUpFields, heatParam = "") => {
  popUpFields = popUpFields || {};
  let geojson;
  if (isPoint) {
    geojson = L.geoJSON(wfs, {
      pointToLayer: (feature, layer) => setStyle(true, layer, heatParam),
      onEachFeature: (feature, layer) =>
        onEachFeature(feature, layer, popUpFields, true)
    });
  } else {
    geojson = L.geoJSON(wfs, {
      style: (feature, layer) => setStyle(false, layer, heatParam),
      onEachFeature: (feature, layer) =>
        onEachFeature(feature, layer, popUpFields, false)
    });
  }
  return geojson;
};

/**
 * @function onEachLayerPoints run this function on each points to  pass a tooltip to the point and
 * add and event listener for mouse over and mouseout
 * @param { Object } feature
 * @param { Object } layer
 * @return { L.geoJSON }
 */
const onEachFeature = (feature, layer, popUpFields, isPoint = false) => {
  // geojsonLayerGlobal = geojsonLayer;
  let htmlSnippet = `<div>`;
  popUpFields.map(key => {
    if (
      feature.properties[key] &&
      !key.includes("code") &&
      key !== "latitude" &&
      key !== "longitude" &&
      key !== "geometry_type" &&
      key !== "global_id"

    ) {
      const featureLabel = convertToTitleCase(key);
      const featureValue = feature.properties[key] ?convertToTitleCase(feature.properties[key]) : '';
      const labelItem = `<div><span><label> ${featureLabel}</label> ${featureValue}</span></div>`;
      htmlSnippet = htmlSnippet.concat(labelItem);
    }
  });
  htmlSnippet = htmlSnippet.concat("</div>");
  layer.bindTooltip(htmlSnippet);
  layer._leaflet_id = feature.id;
  if (!isPoint) {
    layer.on({ mouseover: mouseOverFeature, mouseout: mouseOutOfFeature });
  }
  return geojsonLayerGlobal;
};
/**
 * @function setStyle
 * @description - sets style for a polygon or a point within a geojson
 * @param { Object }  - feature
 * @param { boolean } - isPoint
 */
const setStyle = (isPoint, layer, heatParam) => {
  if (!isPoint) {
    return geoJsonPolygonStyles(heatParam);
  } else {
    if (!layer) throw new Error(" You need to provide a layer parameter");
    const latlng = layer;
    const circle = L.circleMarker(latlng, geojsonMarkerStyle(heatParam));
    return circle;
  }
};
/**
 *@function mouseOverFeature event listener for when we hover over a feature
 *@param { Event } event object
 */
const mouseOverFeature = e => {
  const layer = e.target || e;
  layer.openTooltip();
  try {
    layer.setStyle({
      weight: 1,
      dashArray: "",
      fillOpacity: 0.8,
      strokeOpacity: 1
    });
  } catch (err) {
    // do nothing s
  }
};
const mouseOutOfFeature = e => {
  const layer = e.target || e;
  layer.closePopup();
  try {
    Emitter.emit("mouseout");
  } catch (err) {
    // do nothing
  }
};
