import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Local inports
import { dispatchActions } from "../../store/actions/action-config.action";
import { Loader, PageTemplate, CategoryItem, Search, SortSelect } from "../common";
import { sortArrayOfObjects, filterTypes } from "../../utils/sort";
import { getData } from "../../api-requests/index";
import urls from "../../utils/urls";

// Constants
const { locations } = urls;
const options =  [
  {value: 'name%20asc', label: 'Name Ascending'},
  {value: 'name%20desc', label: 'Name Descending'},
]

class States extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      displayedStates: null,
      sortOrder: options[0]
    }
  }

  componentDidMount() {
    const proxyGetStates = () => {
      return getData({ url: locations });
    };
    this.props.dispatchActions("GET_STATES", { func: proxyGetStates });
  }

  componentDidUpdate(prevProps, prevState) {
    const nextProps = this.props;
    const nextState = this.state;
    if (prevProps.getStatesPending && nextProps.getStatesPayload) {
      this.setStatesFromProps();
    }
  }

  setStatesFromProps = () => {
    this.setState(() => {
      return {
        displayedStates: this.props.getStatesPayload.result
      };
    });
  }

  onSortChange = sortOrder => {
    this.setState({ sortOrder }, this.sortDisplayedStates);
  }

  onClearSearchText = () => {
    this.setState({
      searchText: ''
    }, this.processDisplayedStates);
  }

  onSearchChanged = event => {
    this.setState({
      searchText: event.target.value
    }, this.processDisplayedStates);
  }

  processDisplayedStates = () => {
    let { searchText, displayedStates } = this.state;
    if (searchText) {
      searchText = searchText.toLowerCase();
      const result = this.props.getStatesPayload.result.filter(st => {
        let { title } = st;
        title = title.toLowerCase();
        return title.includes(searchText);
      });

      displayedStates = result;
      this.setState({ displayedStates }, this.forceUpdate);
    } else {
      this.setStatesFromProps();
    }
  }

  sortDisplayedStates = () => {
    const { sortOrder, displayedStates } = this.state;
    const descending = sortOrder.value !== options[0].value;
    let sortedStates = sortArrayOfObjects(displayedStates, filterTypes.alpha, 'title', descending);
    this.setState({
      displayedStates: sortedStates
    }, this.forceUpdate);
  }

  render() {
    const { getStatesPending } = this.props;
    const { displayedStates } = this.state;
    const getStatesPayload = displayedStates;
    const stateCount = (getStatesPayload && getStatesPayload.length) || 0;

    // reshapes the result object to meet propTypes expected by CategoryItem
    const reshape = (result, datasets) => ({...result, datasets: datasets});

    return (
      <PageTemplate path={this.props.match.path}>
        <div className="container-fluid">
          <div className="row-fluid">
            <div className="span10 offset1">
              <div className="row-fluid">
                <div className="module states-overview">
                  <div className="module-content">
                    <Search
                      placeholder="Search states..."
                      value={this.state.searchText}
                      onChange={this.onSearchChanged}
                      defaultValue={this.state.searchText}
                      onClearText={this.onClearSearchText}
                    />
                    <div className="search-heading">
                      <h1 className="heading-xl">
                        <b>{ !getStatesPending && stateCount }</b> { !getStatesPending && (stateCount <= 1? 'state': 'states') }
                      </h1>
                      <div className="dropdown">
                        <span className="dropdown-label">Order by:</span>
                        <SortSelect value={this.state.sortOrder} options={options} onChange={this.onSortChange} />
                      </div>
                    </div>
                    <ul className="box-grid">
                      { getStatesPending
                        ? ( <Loader show={true}/> )
                        : getStatesPayload
                          ? ( getStatesPayload.map((s, index) => {
                                return (
                                  <CategoryItem
                                    key={index}
                                    category={reshape(s, getStatesPayload)}
                                    categoryName="state" />
                                );
                            }))
                          : null
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageTemplate>
    );
  }
}

const mapStateToProps = ({ getStates }) => ({
  getStatesPending: getStates.pending,
  getStatesError: getStates.error,
  getStatesPayload: getStates.payload
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatchActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(States));
