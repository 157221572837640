import React, { Component } from 'react'
import PropTypes from 'prop-types'

class CheckBox extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isChecked: false
    }
  }

  componentDidMount () {
    if(this.props.onLoad){
      this.setState({
        isChecked: this.props.onLoad()
      })
    }
  }

  onCheckChanged = (e) => {
    this.setState({
      isChecked: e.target.checked
    })
    this.props.onChange(e)
  }

  render () {
    return (
      <input type='checkbox' checked={this.state.isChecked} onChange={this.onCheckChanged}></input>
    )
  }
}

CheckBox.propTypes = {
  onChange: PropTypes.func.isRequired
}

export default CheckBox
