import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";


const ActivityItem = ({ activity }) => {
  const { activity_type, user_id, data, timestamp } = activity || {};
  const obj = data.package || data.group || {};

  return (
    <li className={`item ${activity_type.replace(' ', '-')}`}>
      <i className={`fa icon fa-${activity_type === 'changed package'? 'sitemap': 'file'}`}></i>
      <p>
        <span className="actor">
          <img
            className="gravatar" width="30" height="30" alt=""
            src="//gravatar.com/avatar/e48145c38381b1582ecaa433e51c43ec?s=30&d=identicon" />
          <a href={`/user/${user_id}`}>{user_id}</a>
        </span>
        {` ${activity_type} `}
        <span>
          <Link to={`/dataset/${obj.name}`}>{obj.title}</Link>
        </span>
        <span className="date" title={`${moment(timestamp, 'YYYYMMDD')}`}>
          {` ${moment(timestamp, 'YYYYMMDD').fromNow()}`}
        </span>
      </p>
    </li>
  );
};

const ActivityStream = ({ activities }) => (
  !activities || activities.length === 0
    ? <div className="no-results">No activities are within this activity stream</div>
    : (
      <ul className="activity">
        {activities.map((activity, index) => (
          <ActivityItem key={index} activity={activity} />
        ))}
      </ul>
    )
)

ActivityStream.propTypes = {
  activities: PropTypes.array.isRequired
};

export default ActivityStream;
