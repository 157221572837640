import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { DownloadBar, SortSelect, Loader } from '../../common'

class ListView extends Component {

  constructor (props) {
    super(props)
    this.listDiv = React.createRef()
  }

  componentDidMount () {
    document.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount () {
    document.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    this.props.onScroll(this.listDiv.current, window.innerHeight)
  }

  render () {
    return (
      <div className='results' ref={this.listDiv}>
        <div className='list-container'>
          <ul className='datasets-list'>
            <li className='list-header'>
              <div className='list-header-dataset'>
                <h3 className='list-title'>Dataset</h3>
                {this.props.datasetList.length !== 0 &&
                  <div className='dropdown'>
                    <span className='dropdown-label'>Order by:</span>
                    <SortSelect value={this.props.sortValue} onChange={this.props.onSortChange} defaultIndex={1}/>
                  </div>
                }
              </div>
              <div className='list-header-resources'>
                <h3 className='list-title'>Resources</h3>
              </div>
            </li>
            {this.props.datasetList.length === 0 ? (!this.props.isLoading && this.props.noResultMessage) : this.props.datasetList}
            {this.props.isLoading &&
              <div className='loading-container'>
                <Loader show={this.props.isLoading}/>
              </div>
            }
          </ul>
          {this.props.downloadList.length > 0 &&
            <DownloadBar
              downloads={this.props.downloadList}
              formats={this.props.downloadFormats}
              onFormatChanged={this.props.onDownloadFormatChanged}
              downloadResources={this.props.downloadResources}
            />
          }
        </div>

      </div>
    )
  }
}

ListView.propTypes = {
  sortValue: PropTypes.object.isRequired,
  onSortChange: PropTypes.func.isRequired,
  downloadList: PropTypes.array.isRequired,
  datasetList: PropTypes.array.isRequired,
  noResultMessage: PropTypes.element.isRequired,
  isLoading: PropTypes.bool,
  downloadFormats: PropTypes.object.isRequired,
  onDownloadFormatChanged: PropTypes.func.isRequired,
  onScroll: PropTypes.func.isRequired,
  downloadResources: PropTypes.func.isRequired
}

export default ListView
