import React from 'react'

const SectorListItem = ({ sector, facet, onClick }) => (
  <div className='home-sector-item' onClick={onClick}>
    <div className='badge badge-number'>{facet}</div>
    <div className='image'>
      <img src={sector.image_display_url} alt={sector.display_name}/>
    </div>
    <h2 className='home-sector-heading'>
      {sector.display_name}
    </h2>
  </div>
)

export default SectorListItem