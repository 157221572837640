import React from 'react';
import { Link } from 'react-router-dom';

import { dispatchActions } from '../../store/actions/action-config.action';
import { getData } from '../../api-requests/index';
import authzHelper from '../../utils/auth';
import urls from '../../utils/urls';

import { PageTemplate, DatasetItem, ActivityStream, Loader } from './index';


class DatasetGroup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      VIEW: "dataset",
      pageSize: 20,
      datasetCount: 0,
      datasetList: [],
      datasetsOffset: 0,
      trackedDatasetList: []
    }
    this.listDiv = React.createRef();
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.fetchActivities(id);
    document.addEventListener('scroll', this.onScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    const payload = this.props.groupPayload;
    if (payload && prevProps.groupPayload !== payload) {
      this.getDatasetPage();
    }

    const dsPayload = this.props.datasetsPayload;
    if (dsPayload && prevProps.datasetsPayload !== dsPayload) {
      this.buildDatasetItems((dsPayload && dsPayload.result) || []);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    this.trackScrolling(this.listDiv.current, window.innerHeight);
  }

  trackScrolling = (el, containerHeight) => {
    const count = this.state.datasetCount || 0;
    if (el && el.getBoundingClientRect().bottom <= containerHeight) {
      if (!this.props.datasetsPending && this.state.trackedDatasetList.length < count) {
        const newOffset = this.state.datasetsOffset + this.state.pageSize;
        this.setState({ datasetsOffset: newOffset}, this.getDatasetPage);
      }
    }
  }

  fetchActivities = (groupId) => {
    const proxyFetch = (id) => {
      const queries = urls.memberQuery({ id });
      const url = this.TYPE === 'sector'
                ? queries.sectorActivities
                : queries.stateActivities;

      return getData({ url: url })
    }
    this.props.dispatchActions(
      `GET_${this.TYPE.toUpperCase()}_ACTIVITIES`,
      {func: () => proxyFetch(groupId) }
    )
  }

  getDatasetPage = () => {
    const fetchDatasetPage = () => {
      const { name } = this.props.groupPayload.result;
      const { datasetsOffset, pageSize } = this.state;
      const queries = urls.memberQuery({ name });
      const query = this.TYPE === "sector"
                  ? queries.sectorPackage
                  : queries.statePackage;

      return getData({
        url: `${query}&start=${datasetsOffset}&rows=${pageSize}`
      }).then(resp => {
          if (resp.result && resp.result.results) {
            const appendList = [...this.state.trackedDatasetList];
            resp.result.results.forEach(r => { appendList.push(r) });
            this.setState({
              trackedDatasetList: appendList
            })
          }
          return resp;
        });
    }
    this.props.dispatchActions('GET_ALL_DATASETS', {func: fetchDatasetPage});
  }

  buildDatasetItems = datasets => {
    let list = this.state.datasetList;
    if (datasets.results.length) {
      list = list.concat(datasets.results.map(d => (
        <DatasetItem key={d.title} dataset={d} />
      )));
    }

    this.setState({
      datasetCount: datasets.count,
      datasetList: list
    });
  }

  DatasetList = ({ title, datasets }) => {
    const { id } = this.props.match.params;
    const qs = this.TYPE === "state"
             ? `location=["${id}"]`
             : `sector=["${id}"]`;

    const { groupPayload } = this.props;
    const group = (groupPayload && groupPayload.result) || {};
    const userAuth = authzHelper(this.props.userDataPayload);

    return (
      <section className="tab-pane active">
        <div className="datasets-summary">
          <div className="dataset-count heading-xl">
            <span className="badge badge-number">
              { this.state.datasetCount }
            </span>
            {`${datasets.length === 1 ? "dataset in" : "datasets in"} ${title}`}
          </div>
          <div className="datasets-actions">
            <Link to={`/datasets?${qs}`}
              className="btn btn-primary btn-large action-item"
              disabled="" type="button"
            >
              <span className="fa fa-search" />
              Explore datasets
            </Link>
            { (userAuth.isSysAdmin || (this.TYPE === "state" && userAuth.canManageState(group))) &&
              <a className="btn btn-primary btn-large action-item" href={`/${this.TYPE}/edit/${group.name}`}>
                <i classaNme="fas fa-wrench"></i> Manage
              </a>
            }
          </div>
        </div>
        <div className="datasets-actions">
        { (userAuth.isSysAdmin || (this.TYPE === "state" && userAuth.canManageState(group))) &&
          <a className="btn btn-primary btn-large action-item" href={`/dataset/new?group=${group.id}`}>
            <i className="fas fa-plus-square"></i> Add Dataset
          </a>
        }
        </div>
        <ul className="datasets-list" ref={this.listDiv}>
          { datasets }
        </ul>
      </section>
    );
  }

}

export default DatasetGroup;
