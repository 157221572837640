import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import PageTemplate from '../common/PageTemplate'

class About extends Component {
  render () {
    return(
      <PageTemplate path={this.props.match.path}>
        <div className='container-fluid'>
          <div className='image-header'>
            <div className='bg-image'>
              <img src='/image/about-bg.jpg' alt='nigeria'></img>
            </div>
            <img src='/image/GRID3-nigeria-logo.png' className='grid-nigeria-logo'></img>
            <h2 className='headline-xxl text-center'>The GRID<sup>3</sup> Nigeria project</h2>
          </div>
          <div className='row-fluid'>
            <div className='span10 offset1'>
              <div className='module'>
                <div className='module-content'>
                  <p className='heading-l'>
                    The Geo-Referenced Infrastructure and Demographic Data for Development (GRID<sup>3</sup>) programme is part of a bigger global initiative which aims to improve access to data for decision making in all participating countries.
                  </p>
                </div>
                <div className='module-content flex'>
                  <div className='image cover'>
                    <img src='/image/data-collectors.jpg' alt='nigeria'></img>
                  </div>
                  <div className='text-m flex2'>
                    <h3 className="title-xl">Background</h3>
                    <p>The GRID<sup>3</sup> Nigeria project works across all states in Nigeria to collect accurate, complete, and geospatially referenced data relevant to a variety of sectors. Over the course of the project <b>over 500,000 points of interest</b> are collected and <b>over 2,000 data collectors</b> are being trained in the use of mobile data collection technology.</p>
                  </div>
                </div>
                <div className='module-content flex'>
                  <div className='image cover'>
                    <img src='/image/population-map.jpg' alt='nigeria-map'></img>
                  </div>
                  <div className='text-m flex2'>
                    <h3 className="title-xl">Project goals</h3>
                    <p>The project aims to increase access to data for use by State Government decision makers, as well as donors, non-profit organisations and private sector partners in Nigeria through the continuous availability of a <b>robust and up-to-date GRID<sup>3</sup> geodatabase.</b> </p>
                    <p>It also aims to increase capacity of data managers to make data available and integrated into planning processes for decision makers through capacity building activities across the country.</p>
                    <p>Finally, the project aims to increase coordination in the collection and management of data in Nigeria through the GRID<sup>3</sup> portal. </p>
                    <p>The ultimate goal of the project is to ensure that the Nigerian people, particularly the poor and vulnerable, benefit from better evidence-based resource distribution and data driven policy-making by decision makers.</p>
                  </div>
                </div>
                <div className='module-content flex'>
                  <div className='image cover'>
                    <img src='/image/portal.jpg' alt='data portal'></img>
                  </div>
                  <div className='text-m flex2'>
                    <h3 className="title-xl">The GRID<sup>3</sup> portal</h3>
                    <p>The points of interest and settlement data collected throughout the course of the project, as well as population estimates and boundary data for the country are housed in the GRID<sup>3</sup> portal, open for all stakeholders to <b>discover, download, and manage data</b>. In addition, as an open source portal, tools can be connected directly to the portal to allow for visualization of the data. </p>
                    <p>The portal also allows you to search for existing programmatic surveys, reports, methodologies, indicators and protocols and access data or reports for completed surveys. This coordinated approach to open data storage, discovery and management ensures that GRID3 aligns with Nigeria’s National Open Data Policy.</p>
                  </div>
                </div>
                <div className='module-content flex'>
                  <div className='image cover'>
                  </div>
                  <div className='text-m flex2'>
                    <h3 className="title-xl">GRID<sup>3</sup> partners</h3>
                    <p>The Global GRID3 programme is funded by the Bill & Melinda Gates Foundation and the UK Department for International Development. It is implemented by WorldPop/Flowminder and UNFPA, and coordinated by CIESIN.</p>
                    <Link to='/partner' className='btn btn-primary'>See all partners</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='image-header grid3-header'>
            <div className='bg-image'>
            </div>
            <img src='/image/grid3global_logo.png' className='grid-logo'></img>
            <h2 className='headline-xxl text-center'>Global GRID<sup>3</sup> Programme</h2>
          </div>

          <div className='row-fluid'>
            <div className='span10 offset1'>
              <div className='module'>
                <div className='module-content'>
                  <p className='heading-l'>
                    Launched in March 2018, the Geo-Referenced Infrastructure and Demographic Data for Development (GRID<sup>3</sup>) initiative aims to facilitate the production, collection, use, and dissemination of high-resolution population, infrastructure and other reference data in support of national sectoral development priorities, humanitarian efforts, and the United Nations’ Sustainable Development Goals (SDGs). 
                  </p>
                </div>
                <div className='module-content text-center'>
                  <a href='http://grid3.org' target='blank' className='btn btn-large btn-primary'>Visit Global GRID<sup>3</sup> website</a>
                </div> 
              </div>
            </div>
          </div>
          <div className='grid3-countries'>
            <img src='/image/nigeria-grid-countries.png' className='grid3 in nigeria'></img>
            <div>
              <h2 className='heading-l '>Countries where Global GRID<sup>3</sup> work is underway</h2>
              <ul className='unstyled'>
                <li>Nigeria</li>
                <li>Democratic Republic of Congo</li>
                <li>Mozambique</li>
                <li>Zambia</li>
              </ul>
            </div>
          </div>
        </div>
      </PageTemplate>
    )
  }
}

export default connect()(About)