import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => (
  <footer className="site-footer">
    <div className="container-fluid">
        <div className="row-fluid">
          <div className="span10 offset1">
            <div className="footer-content">
              <div className="footer-links">
                <div>
                  <h3 className="footer-title">GRID<sup>3</sup> Nigeria</h3>
                  {/* <ul className="unstyled">*/}
                  {/*  <li>*/}
                  {/*    <a href="#">Contact</a>*/}
                  {/*  </li>*/}
                  {/*  <li>*/}
                  {/*    <Link to='/releases'>Releases</Link>*/}
                  {/*  </li>*/}
                  {/*  <li>*/}
                  {/*    <a href="#">Privacy Policy</a>*/}
                  {/*  </li>*/}
                  {/*</ul>*/}
                </div>
                <div className='government-logo'>
                  <img src='/image/nigerian-government-logo.png' alt='Federal Republic of nigeria' />
                </div>
              </div>
              <div className="footer-partner">
                  <h3 className="footer-title">Part of the Global GRID<sup>3</sup> programme</h3>
                  <img src="/image/GRID3-logo_white.png" className="global-logo" alt="GRID3 global logo" />
              </div>
            </div>
          </div>
        </div>
    </div>
    <div className="container-fluid">
        <div className="footer-disclaimer">
          <div className="row-fluid">
            <div className="span10 offset1">
              {/*<p>&copy; 2018 eHealth Africa</p>*/}
              <p>All rights reserved except published datasets/resources and metadata.</p>
              <p>All datasets/resources including metadata published on grid-nigeria.org are licensed under GRID</p>
            </div>

          </div>
        </div>
    </div>
  </footer>
)

export default Footer
