import React from 'react'
import { Link } from 'react-router-dom'

const DatasetItem = props => {
  const { dataset, children } = props

  return (
    <li className='dataset'>
      <div className='dataset-info dataset-content'>
        <h3 className="dataset-heading">
          {dataset.private &&
            <span className="dataset-private label label-inverse">
              <i className="fa fa-lock"></i> Private
            </span>
          }
          <Link className='title' to={`/dataset/${dataset.name}`}>{dataset.title}</Link>
        </h3>
        <div className='description dataset-description' dangerouslySetInnerHTML=
          {{
            __html: dataset.notes
          }}></div>
      </div>
      {children}
    </li>
  )
}

export default DatasetItem
