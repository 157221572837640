import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";


// Local inports
import { Loader, PageTemplate, CategoryItem, Search, SortSelect } from "../common";
import { dispatchActions } from "../../store/actions/action-config.action";
import { sortArrayOfObjects, filterTypes } from "../../utils/sort";
import { getData } from "../../api-requests/index";
import urls from "../../utils/urls";

// Constants
const { sector } = urls;
const options = [
  { value: "name%20asc", label: "Name Ascending" },
  { value: "name%20desc", label: "Name Descending" }
];

class Sectors extends Component {

  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      displayedSectors: null,
      sortOrder: options[0]
    }
  }

  componentDidMount() {
    const proxyGetSectors = () => {
      return getData({
        url: sector,
        context: "POST",
        inputData: {all_fields: true, include_extras: true}
      });
    };
    this.props.dispatchActions("GET_SECTORS", { func: proxyGetSectors });
  }

  componentDidUpdate(prevProps, prevState) {
    const nextProps = this.props;
    const nextState = this.state;
    if (prevProps.getSectorsPending && nextProps.getSectorsPayload) {
      this.setStatesFromProps();
    }
  }

  setStatesFromProps = () => {
    this.setState(() => {
      return {
        displayedSectors: this.props.getSectorsPayload.result
      };
    });
  }

  onSortChange = sortOrder => {
    this.setState({ sortOrder }, this.sortDisplayedSectors);
  }

  onClearSearchText = () => {
    this.setState({
      searchText: ''
    }, this.processDisplayedSectors);
  }

  onSearchChanged = event => {
    this.setState({
      searchText: event.target.value
    }, this.processDisplayedSectors);
  }

  processDisplayedSectors = () => {
    let { searchText, displayedSectors } = this.state;
    if (searchText) {
      searchText = searchText.toLowerCase();
      const result = this.props.getSectorsPayload.result.filter(st => {
        let { title } = st;
        title = title.toLowerCase();
        return title.includes(searchText);
      });

      displayedSectors = result;
      this.setState({ displayedSectors }, this.forceUpdate);
    } else {
      this.setStatesFromProps();
    }
  }

  sortDisplayedSectors = () => {
    const { sortOrder, displayedSectors } = this.state;
    const descending = sortOrder.value !== options[0].value;
    let sortedSectors = sortArrayOfObjects(displayedSectors, filterTypes.alpha, 'title', descending);
    this.setState({
      displayedSectors: sortedSectors
    }, this.forceUpdate);
  }

  render() {
    const { getSectorsPending } = this.props;
    const { displayedSectors } = this.state;
    const getSectorsPayload = displayedSectors;
    const sectorCount = (getSectorsPayload && getSectorsPayload.length) || 0;

    // reshapes the result object to meet propTypes expected by CategoryItem
    const reshape = (result, datasets) => ({...result, datasets: datasets});

    return (
      <PageTemplate path={this.props.match.path}>
        <div className="container-fluid">
          <div className="row-fluid">
            <div className="span10 offset1">
              <div className="row-fluid">
                <div className="module states-overview">
                  <div className="module-content">
                    <Search
                      placeholder="Search sectors..."
                      value={this.state.searchText}
                      onChange={this.onSearchChanged}
                      defaultValue={this.state.searchText}
                      onClearText={this.onClearSearchText}
                    />
                    <div className="search-heading">
                      <h1 className="heading-xl">
                        <b>{ !getSectorsPending && sectorCount }</b> { !getSectorsPending && (sectorCount <= 1 ? "sector" : "sectors") }
                      </h1>
                      <div className="dropdown">
                        <span className="dropdown-label">Order by:</span>
                        <SortSelect value={this.state.sortOrder} options={options} onChange={this.onSortChange} />
                      </div>
                    </div>
                    <ul className="box-grid">
                      { getSectorsPending
                        ? ( <Loader show={true}/> )
                        : getSectorsPayload
                          ? ( getSectorsPayload.map((s, index) => {
                                return (
                                  <CategoryItem
                                    key={index}
                                    category={reshape(s, getSectorsPayload)}
                                    categoryName="sector" />
                                );
                            }))
                          : null
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageTemplate>
    );
  }
}

const mapStateToProps = ({ getSectors }) => ({
  getSectorsPending: getSectors.pending,
  getSectorsError: getSectors.error,
  getSectorsPayload: getSectors.payload
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatchActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sectors));
