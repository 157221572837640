import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Search extends Component {

  render() {
  
    return (
      <div className='search-form'>
        <div className='search-input control-group'>
          <i className='fa fa-search'></i>
          <input
            type='text'
            className='search'
            name='q'
            autoComplete='on'
            onChange={this.props.onChange}
            value={this.props.value}
            placeholder={this.props.placeholder}
          />
          {this.props.value !== '' && <button type='button' value='search' onClick={this.props.onClearText}>
            <i className='fa fa-times'></i>
          </button>}
        </div>
      </div>
    )
  }
}

Search.propTypes = {
  value: PropTypes.string.isRequired,
  onClearText: PropTypes.func.isRequired
}

export default Search