import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

class Resource extends Component {

  onResourceChange = (resource) => {
    this.props.onResourceChange(this, resource)
  }

  render() {
    const { dataset, children } = this.props
    return (
      <li className='dataset selected' key={dataset.id}>
        <h3 className='title'>{dataset.title}<Link className='title' to={`/dataset/detail/${dataset.id}`}><i className='fa icon fa-arrow-circle-up'></i></Link></h3>
        <ul className='resources'>
          { children }
        </ul>
      </li>
    )
  }
}

Resource.propTypes = {
  dataset: PropTypes.object.isRequired
}

export default Resource