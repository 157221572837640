import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import * as utils from '../../utils/string.utils';

class CategoryItem extends Component {
  render() {
    const {
      id,
      name,
      title,
      description,
      image_display_url,
      package_count
    } = this.props.category;
    const { datasets, categoryName } = this.props;

    return (
      <li className="box box-v">
        <Link to={`/${categoryName}/${name}`}>
          <div className="image">
            <img src={image_display_url} alt={title} className="media-image" />
          </div>
          <div className="info">
            <h2 className="title-m">{title}</h2>
            <p>{ utils.truncateString(description) }</p>
          </div>
          <div className="dataset-count heading-m">
            <span className="badge badge-number title-m">{package_count}</span>
          datasets
          </div>
        </Link>
      </li>
    );
  }
}

CategoryItem.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    datasets: PropTypes.arrayOf(PropTypes.any).isRequired,
    image_display_url: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  categoryName: PropTypes.string.isRequired
};

export default CategoryItem;
