import React from 'react';
import ReactDOM from 'react-dom';
import ReduxToastr from 'react-redux-toastr'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store/index';

var root = document.getElementById('root');
ReactDOM.render(
<Provider store={ store }>
  <div>
    <ReduxToastr
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      position="top-center" />
    <App {...(root.dataset)} />
  </div>
</Provider>
, root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
