import React, { Component } from 'react'
import { connect } from 'react-redux'

import PageTemplate from '../common/PageTemplate'

class Releases extends Component {
  render () {
    return(
      <PageTemplate path={this.props.match.path}>
        <div className='container-fluid'>
          <div className='image-header'>
            <div className='bg-image'>
              <img src='/image/home-bg.jpg' alt='nigeria'></img>
            </div>
            <h2 className='headline-xxl text-center'>GRID<sup>3</sup> Data Portal Releases</h2>
          </div>
          <div className='row-fluid'>
            <div className='span10 offset1'>
              <div className='module'>
                <div className='module-content'>
                  <p className='headline-xl'>
                    Current Release Note
                  </p>
                </div>
                <div className='module-content flex partner'>
                  <div className='text-m flex2'>
                    <h3 className="title-l">GRID<sup>3</sup> Data Quality Check</h3>
                    <p>GRID<sup>3</sup> Release notes contain regular updates for</p>
                    <ul>
                      <li>Changes and Improvements</li>
                      <li>Known Issues</li>
                      <li>Plans for next release and</li>
                      <li>Additional Documents</li>
                    </ul>
                    <p><a href='https://docs.google.com/document/d/1pK37VAjTeAF7qCAMVawAcuz_F9BSocy2yjNFRJf0J4Q/edit#heading=h.i0jpeer5cmef' target='blank'>Release Note 30 Oct 2018</a></p>
                  </div>
                </div>
              </div>
              <div className='module'>
                <div className='module-content'>
                  <p className='headline-xl'>
                    Recent Release Notes
                  </p>
                </div>
                <div className='module-content flex partner'>
                  <div className='text-m flex2'>
                    <a href='https://docs.google.com/document/d/1pK37VAjTeAF7qCAMVawAcuz_F9BSocy2yjNFRJf0J4Q/edit#heading=h.i0jpeer5cmef' target='blank'>Release Note 30 Oct 2018</a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </PageTemplate>
    )
  }
}

export default connect()(Releases)