import * as React from "react";
import * as queryString from "query-string";
import { Link } from "react-router-dom";


export const ResourcesList = ({
  dataset, selectedResources, toggleSelection,
  markAllHandler, downloadHandler, userAuth
}) => {
  const { name, resources } = (dataset && dataset.resources && dataset) || { name:'', resources:[] };
  const canManage = userAuth.canManageDataset(dataset);

  return (
    <div className="tab-content">
      <section className="resources">
        <div className={`resource-main-actions ${!canManage? "empty": ""} `}>
        { canManage &&
          <a className="btn btn-primary btn-large" href={`/dataset/edit/${dataset.name}`}>
            <i classaNme="fas fa-wrench"></i> Manage
          </a>
        }
        </div>
        <ul className="resource-list">
        { resources.map( (resx, idx) => {
          const query = queryString.stringify({ url: resx.url, name: resx.name });
          return (
            <li key={idx} className="resource-item">
              <div className="checkbox">
                <label className="check-default">
                  <input type="checkbox"
                    checked={selectedResources.indexOf(resx.id) !== -1 }
                    onChange={e => toggleSelection(e, resx.id) } />
                  <span />
                </label>
              </div>
              <div className="info">
                <Link className="title-m" to={`/dataset/${name}/resource/${resx.id}`}>
                  {resx.name} <span className="label" data-format={resx.format}>{resx.format}</span>
                </Link>
                <p>{resx.description}</p>
              </div>
              <div className="resource-actions">
                <Link className="btn" query={query} to={`/dataset/${name}/resource/${resx.id}`}>
                  Explore
                </Link>
              </div>
            </li>
          );
        })}
        </ul>

        <div className="resource-list-actions">
          <button type="button" className="btn btn-default btn-mark-all" onClick={markAllHandler}>
            <span className="fa fa-check" />{" "}
            { selectedResources.length === resources.length ? "Unmark All" : "Mark All" }
          </button>
          <button id="download-resources" type="button" className="btn btn-primary"
            onClick={downloadHandler} disabled={selectedResources.length === 0}>
            <span className="fa fa-download" /> Download {
              selectedResources.length > 1
              ? `${selectedResources.length} Resources`
              : selectedResources.length === 1 && " Resource"
            }
          </button>
        </div>
      </section>
    </div>
  );
};
