import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'
import { toastr } from 'react-redux-toastr'

import { PageTemplate, Loader } from '../common'
import RecentDatasets from './components/RecentDatasets'
import SectorListItem from './components/SectorListItem'
import { loadGeojson, initMap } from '../../utils/maps'
import { convertToTitleCase } from '../../utils/string.utils'
import { getData } from '../../api-requests'
import urls from '../../utils/urls'
import { dispatchActions } from '../../store/actions/action-config.action'


class Home extends Component {

  constructor(props) {
    super(props)
    this.nigerianMap = React.createRef()
    this.state = {
      locationCount: 0,
      selectedLocation: null,
      searchText: '',
      mapPending: false
    }
  }


  componentDidMount () {
    const nigeria_map = initMap(this.nigerianMap.current, {
      scrollWheelZoom: false,
      closePopupOnClick: false,
      zoomControl: false,
      zoomSnap: 0.8,
      zoom: 6
    }, false)

    const fetchSectors = () => (
      getData({ url: urls.sectors, context: 'POST', inputData: { all_fields: true, include_dataset_count: true, include_resources: false } })
    )

    this.props.dispatchActions('GET_SECTORS', { func: fetchSectors })
    this.loadSectorsByMapSelection()

    getData({ url: urls.locations })
      .then(res => {
        this.setState({ locationCount: (res && res.result && res.result.length) || 0 })
      })

    this.setState({ mapPending: true })
    getData({ url: urls.homeMap })
      .then(res => {
        getData({ url: `${urls.allDatasets}&start=0&rows=3` })
        .then(r => {
          const { organization } = r.result.facets
          loadGeojson(res.result, nigeria_map, this.loadSectorsByMapSelection.bind(this), organization)
          this.setState({ mapPending: false })
        })
        .catch(err => {
          toastr.error('Network Error', `${err.toString()} location facets`)
          this.setState({ mapPending: false })
        })
      })
      .catch(err => {
        toastr.error('Network Error', `${err.toString()} Map Coordinates`)
      })
  }

  loadSectorsByMapSelection = (location) => {
    const getLocationSectors = () => {
      let locQuery = ''
      if (location) {
        const loc = location.toLowerCase()
        locQuery = `&fq=organization:${loc}`
        this.setState({ locationCount: 1, selectedLocation:loc })
      } else {
        this.setState({ selectedLocation: null })
      }
      return getData({ url: `${urls.allDatasets}&start=0&rows=3&sort=metadata_modified%20desc${locQuery}` })
    }
    this.props.dispatchActions('GET_HOME_SECTORS', { func: getLocationSectors })
  }

  onSectorClick = (location, sectorName) => {
    const locQuery = location ? `location=["${location}"]` : ''
    const sectorQuery = sectorName ? `sector=["${sectorName}"]` : ''
    const url =  `/datasets?${locQuery}&${sectorQuery}`
    this.props.history.push(url)
  }

  onShowAll = () => {
    let url = '/datasets'
    if (this.state.selectedLocation) {
      url = `${url}?location=["${this.state.selectedLocation}"]`
    }
    this.props.history.push(url)
  }

  onSearch = () => {
    this.props.history.push(`/datasets?q=${this.state.searchText}`)
  }

  onSearchChanged = e => {
    this.setState({
      searchText: e.target.value
    })
  }

  render () {
    const sectors = (this.props.sectorsPayload && this.props.sectorsPayload.result) || [];
    const facets = (this.props.mapSectorPayload && this.props.mapSectorPayload.result
      && this.props.mapSectorPayload.result.facets && this.props.mapSectorPayload.result.facets.groups) || null
    const datasetCount = (this.props.mapSectorPayload &&
      this.props.mapSectorPayload.result && this.props.mapSectorPayload.result.count) || 0

    const recentDatasets = (this.props.mapSectorPayload && this.props.mapSectorPayload.result &&
      this.props.mapSectorPayload.result.results) || []

    return(
      <PageTemplate isHome path={this.props.match.path}
      onSearch={this.onSearch}
      searchText={this.state.searchText}
      onSearchChanged={this.onSearchChanged}>
        <div className='container-fluid'>
          <div className='row-fluid'>
            <div className='module span10 offset1'>
              <h2 className='widget-headline text-center'>
                <span className='badge badge-number'>{datasetCount}</span> available datasets in <b>{this.state.locationCount}</b> states and <b>{Object.keys(facets || {}).length}</b> sectors
              </h2>
              <div className='module-content map-sector-widget'>
                <div className='home-map'>
                  <Loader show={this.state.mapPending} customStyle={loaderStyle}/>
                  <div ref={this.nigerianMap}></div>
                </div>
                <div className='home-sectors'>
                <Loader show={this.props.mapSectorPending} customStyle={loaderStyle}/>
                {
                  datasetCount === 0 ?
                  <div>
                  {
                    this.props.mapSectorPending ? null :
                    <span>No datasets found in {convertToTitleCase(this.state.selectedLocation || 'all locations')}</span>
                  }
                  </div> :
                  sectors.map(sector => {
                    const count = (facets && facets[sector.name]) || 0;
                    return (count !== 0 && <SectorListItem sector={sector} key={sector.id} facet={count} onClick={this.onSectorClick.bind(this, this.state.selectedLocation, sector.name)}/>)
                  })
                }
                </div>
              </div>
              <div className='divider'>
                <div className='btn btn-primary btn-large' onClick={this.onShowAll}>show <span>{datasetCount}</span> datasets</div>
              </div>
            </div>
          </div>
          <div className='row-fluid'>

            <div className='module span10 offset1'>
              <h2 className='headline-xxl text-center'>
                Recently added datasets
              </h2>
              <div className='module-content flex'>
                {recentDatasets.map(dataset => (
                  <RecentDatasets dataset={dataset} key={dataset.id}/>
                ))}
              </div>

            </div>
          </div>

          <div className='row-fluid'>
            <div className='module span10 offset1'>
              <div className='support-teaser'>
                <div className='bg-image'><img src='/image/support-bg.jpg' alt='support' /></div>
                <h2 className='headline-xxl text-center'>
                  Need help?
                </h2>
                <h3 className='title-xl text-center'>Learn how to use the GRID<sup>3</sup> Nigeria data portal</h3>
                <Link to='/support' className='btn btn-large btn-block btn-primary'>Frequently asked questions</Link>
                </div>
            </div>
          </div>

          <div className='row-fluid'>
            <div className='module span10 offset1'>
              <div className='module-content flex'>
                <div className='teaser'>
                  <h2 className='headline-xxl'>
                    What is GRID<sup>3</sup> Nigeria?
                  </h2>
                  <p><b>The Geo-Referenced Infrastructure and Demographic Data for Development (GRID<sup>3</sup>) programme</b> is part of a bigger global initiative which aims to improve access to data for decision making in all participating countries.</p>
                  <p>The GRID<sup>3</sup> Nigeria project works across all states in Nigeria to collect accurate, complete, and geospatially referenced data relevant to a variety of sectors.</p>
                  <Link to='/about' className='link'>read more</Link>
                </div>
                <div className='teaser'>
                  <h2 className='headline-xxl'>
                    About the Global GRID<sup>3</sup> programme
                  </h2>
                  <p>Launched in March 2018, the initiative aims to facilitate the production, collection, use, and dissemination of high-resolution population, infrastructure and other reference data in support of national sectoral development priorities, humanitarian efforts, and the United Nations’ Sustainable Development Goals (SDGs). </p>
                  <a href='http://grid3.org' target='blank' className='link'>Visit Global GRID<sup>3</sup> website</a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </PageTemplate>
    )
  }
}

const loaderStyle = { backgroundColor: 'transparent' }

const mapStateToProps = ({ getHomeSectors, getSectors }) => ({
  mapSectorPending: getHomeSectors.pending,
  mapSectorError: getHomeSectors.error,
  mapSectorPayload: getHomeSectors.payload,
  sectorsPending: getSectors.pending,
  sectorsError: getSectors.error,
  sectorsPayload: getSectors.payload
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({ dispatchActions }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(Home)
