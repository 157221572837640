// jshint ignore: start
// third party imports
import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";

// pages
import Home from "./components/home";
import About from "./components/about/index";
import Partner from "./components/about/Partner";
import Releases from "./components/about/Releases";
import States from "./components/states/List";
import State from "./components/states/Details";
import Sectors from "./components/sectors/List";
import Sector from "./components/sectors/Details";
import DatasetDetail from "./components/dataset-detail/index";
import Datasets from "./components/datasets";
import Support from "./components/Support";
import ResourceDetail from "./components/resource-detail/index";

import { dispatchActions } from "./store/actions/action-config.action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";


class App extends Component {

  componentDidMount() {
    const userData = atob(this.props.ctxdata || '');
    window.localStorage.setItem("userData", userData);

    const proxySetData = () => {
      return new Promise(function(resolve, reject) {
        try {
          let data = (userData !== ''? JSON.parse(userData): {});
          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    }
    this.props.dispatchActions("SET_USER_DATA", { func: proxySetData })
  }

  render() {
    return (
      <BrowserRouter>
        <Route
          render={() => (
            <React.Fragment>
              <Route exact path="/" component={Home} />
              <Route exact path="/about" component={About} />
              <Route exact path="/partner" component={Partner} />
              <Route exact path="/releases" component={Releases} />
              <Route exact path="/support" component={Support} />
              <Route exact path="/states" component={States} />
              <Route exact path="/state/:id" component={State} />
              <Route exact path="/sectors" component={Sectors} />
              <Route exact path="/sector/:id" component={Sector} />
              <Route exact path="/datasets" component={Datasets} />
              <Route path="/dataset/:id" component={DatasetDetail} />
              <Route path="/resource/:id" component={ResourceDetail} />
            </React.Fragment>
          )}
        />
      </BrowserRouter>
    );
  }
}


const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatchActions }, dispatch);

export default connect(null, mapDispatchToProps)(App);
