import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr'
import { connect } from 'react-redux';


import GeoJSONViewer, {ImageViewer, NullViewer } from './components/DataViewer';
import { dispatchActions } from '../../store/actions/action-config.action';
import { Loader, Metadata, PageTemplate } from '../common';
import { Modal } from "../common/Modal";

import { getData } from '../../api-requests/index';
import * as utils from '../../utils/string.utils';
import urls, { baseUrl } from '../../utils/urls';
import queryString from "query-string";



const WFSFormat = {
  CSV: 'csv',
  GeoJSON: 'application/json'
}


class ResourceDetail extends React.Component {

  constructor(props) {
    super(props);
    this.VIEWS = { data:'data', metadata:'metadata', map:'map' };
    this.state = {
      VIEW: this.VIEWS.data,
      showEmbedModal: false,
      modalHeight: 400,
      modalWidth: 400
    }
    this.resourceId = this.props.match.params.id;
    this.embedLink = `${baseUrl}/resource/${this.resourceId}`;
    this.metadataExcludeList = ['id', 'description', 'name'];
    this.loaderStyle = { background:'transparent', position:'relative', height:'500px' };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const proxyGetResource = () => {
      return getData({
        url: urls.memberQuery({ id }).singleResource
      });
    }
    this.props.dispatchActions("GET_RESOURCE", {func: proxyGetResource});
  }

  isImageUrl = (url) => {
    if (url) {
      const ext = url.substr(url.lastIndexOf('.'));
      return [
        '.bmp', '.jpg', '.jpeg', '.gif', '.png'
      ].indexOf(ext) !== -1;
    }
    return false;
  }

  performWFSProbe = (qs) => {
    if (qs && qs.service === 'WFS' && qs.outputFormat) {
      const format = qs.outputFormat;
      for (let key in WFSFormat) {
        if (WFSFormat[key] === format) {
          return {isViewable: true, format: format}
        }
      }
    }
    return null;
  }

  downloadHandler = (evt, resource) => {
    const resources = [
      {'id': resource.id, 'format': resource.format.toLowerCase()}
    ];

    getData({
      url: urls.prepareDownloads, context: 'POST',
      inputData: { resources, include_metadata: true }
    }).then(resp => {
        if (resp.result && resp.result.file_id) {
          // download zip file by id
          window.location.href = `${urls.zippedResourcesDownload}${resp.result.file_id}`;
        } else {
          // Resource(s) were not archived
          toastr.warning('Download Error', 'Encountered an error archiving resources');
        }
      })
      .catch(err => {
        // show download error
        toastr.error('Download Error', `${err.toString()}`);
      })
  }

  renderResourceData = (resource) => {
    const sourceUrl = (resource && resource.url) || null;
    const loaderStyle = {
      background:'transparent', position:'relative', height:'500px'
    };
    if (!sourceUrl) {
      if (this.props.resourcePending) {
        return <Loader show={true} customStyle={loaderStyle} />
      }
      return <NullViewer sourceUrl={sourceUrl} />
    }

    // verify value is url
    const urlparts = queryString.parseUrl(sourceUrl);
    const isUrl = (urlparts && urlparts.url && urlparts.url.startsWith('http'));
    if (!isUrl) {
      return <NullViewer sourceUrl={sourceUrl} />
    }

    if (this.isImageUrl(urlparts.url)) {
      return <ImageViewer sourceUrl={sourceUrl} />
    }

    const wfs = this.performWFSProbe(urlparts.query);
    if (wfs && wfs.isViewable) {
      switch (wfs.format) {
        case WFSFormat.GeoJSON:
          return <GeoJSONViewer sourceUrl={sourceUrl} />
      }
    }
    return <NullViewer sourceUrl={sourceUrl} />
  }

  renderResourceMetadata = (resource) => {
    return(
      <React.Fragment>
        <Metadata
          excludeList={this.metadataExcludeList}
          dataObject={resource}
          type='resource' />
      </React.Fragment>
    )
  }

  copyEmbedCode = (evt, name) => {
    this.toggleEmbedModal();
    evt.preventDefault();

    const { modalWidth, modalHeight } = this.state;
    const embedurl = `<iframe width="${modalWidth}" height="${modalHeight}" src="${this.embedLink}" frameBorder="0"></iframe> `;
    navigator.clipboard.writeText(embedurl);
    toastr.info('Embed Code', 'The embed link has been copied to your clipboard');
  }

  toggleEmbedModal = () => {
    const { showEmbedModal } = this.state;
    this.setState({
      showEmbedModal: !showEmbedModal
    });
  }

  resizeEmbedModal = (evt, name) => {
    const { value } = evt.target;
    const attrname = `modal${name}`;

    this.setState({
      [attrname]: value,
    });
  }

  render() {
    const { match, resourcePending, resourcePayload } = this.props;
    const resource = (resourcePayload && resourcePayload.result) || {};
    const qs = queryString.parse(this.props.location.search);

    return (
      <PageTemplate path="/datasets">
      { resourcePending
        ? <Loader show={true} customStyle={this.loaderStyle} />
        : (
        <React.Fragment>
          <div className="container-fluid">
            <div className="toolbar">
              <ol className="breadcrumb">
                <li>
                  <Link to="/"> <i className="fa fa-home"></i></Link>
                </li>
                <li>
                  <Link to="/datasets">Datasets</Link>
                </li>
                { qs.dataset
                  ? (
                  <li>
                    <Link to={`/dataset/${qs.dataset}`}>
                      {utils.convertToTitleCase(
                        (qs.dataset || "").replace(/-/g, ' ').replace(/_/g, ' ')
                      )}
                    </Link>
                  </li>
                  ): (
                    /* dummy crumb put here intentionally */
                    <li>Resource</li>
                  )}
                <li className="active">
                  {resource.name}
                </li>
              </ol>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row-fluid">
              <div className="span10 offset1">
                <div className="row-fluid">
                  <section className="resource-container">
                    <div className='module'>
                      <h1 className="page-title">{resource.name}</h1>
                      <div className="module-content flex">
                        <p>{resource.description}</p>
                        <div className="resource-actions">
                            <a className="btn btn-primary btn-large"
                               onClick={() => this.setState({ showEmbedModal: true }) }>
                              Embed
                            </a>
                            <a className="btn btn-primary btn-large"
                              onClick={(evt) => this.downloadHandler(evt, resource)}>
                              Download
                            </a>
                        </div>
                      </div>
                    </div>
                    <div className='module'>

                      <div className='module-content'>

                        <ul className="nav nav-tabs">
                          <li className={ this.state.VIEW === this.VIEWS.data ? "active": "" }>
                            <a onClick={() => this.setState({ VIEW: this.VIEWS.data })}>
                              Data Explorer
                            </a>
                          </li>
                          <li className={ this.state.VIEW === this.VIEWS.metadata ? "active": "" }>
                            <a onClick={() => this.setState({ VIEW: this.VIEWS.metadata })}>
                              Metadata
                            </a>
                          </li>{/*
                          <li className={ this.state.VIEW === this.VIEWS.map ? "active": "" }>
                            <a onClick={() => this.setState({ VIEW: this.VIEWS.map })}>
                              <i className="fa icon fa-globe-africa"></i> Map
                            </a>
                          </li> */}
                        </ul>

                        <div className="tab-content">
                          { this.state.VIEW === this.VIEWS.data &&
                            this.renderResourceData(resource)
                          }
                          { this.state.VIEW === this.VIEWS.metadata &&
                            this.renderResourceMetadata(resource)
                          }
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>

          <Modal
            embedLink={this.embedLink}
            width={this.state.modalWidth}
            height={this.state.modalHeight}
            display={this.state.showEmbedModal}
            embedCopyHandler={this.copyEmbedCode}
            closeModalHandler={this.toggleEmbedModal}
            />
        </React.Fragment>
        )
      }
      </PageTemplate>
    )
  }
}

const mapStateToProps = ({ getResource }) => {
  return {
    resourcePending: getResource.pending,
    resourcePayload: getResource.payload,
    resourceError: getResource.error,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatchActions }, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResourceDetail));
