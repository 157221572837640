/**
 * @file Determines all the variables we will be needing for the redux store
 * The redux store promises to have only one implementation for action creators and action types
 * and reducers hence all the variables are being preset
 * All defined variables will come with a doc that represents what they do
 */

/**
 * Third Party Imports
 */
// NA

/**
 * Local Imports
 */
// NA

/**
 * @const - this will populate the redux store with the required actions
 * ****************************************
 * TO UPDATE THIS CONFIG VARIABLE
 * ****************************************
 * *** Key Represents the reference to the layer ***
 * *** Values could be an array or string that represents
 * all the sublayers required to be loaded
 */
export const reduxVariables  = {

  GET_HOME_SECTORS: 'GET_HOME_SECTORS',
  MAP_DATA_CHANGED: 'MAP_DATA_CHANGED',
  ADMIN_BOUNDARY:'ADMIN_BOUNDARY',

  GET_LOCATIONS: 'GET_LOCATIONS',
  GET_TAGS: 'GET_TAGS',

  GET_DATASET_ACTIVITIES: 'GET_DATASET_ACTIVITIES',
  GET_ALL_DATASETS: 'GET_ALL_DATASETS',
  GET_DATASET: 'GET_DATASET',

  GET_RESOURCE: 'GET_RESOURCE',
  GET_RESOURCE_DATA: 'GET_RESOURCE_DATA',

  GET_STATE_ACTIVITIES: 'GET_STATE_ACTIVITIES',
  GET_STATES: 'GET_STATES',
  GET_STATE: 'GET_STATE',

  GET_SECTOR_ACTIVITIES: 'GET_SECTOR_ACTIVITIES',
  GET_SECTORS: 'GET_SECTORS',
  GET_SECTOR: 'GET_SECTOR',

  SET_USER_DATA: 'SET_USER_DATA'
};
