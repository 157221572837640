import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CheckBox from '../datasets/components/CheckBox'

class SectorItem extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isSelected: props.isSelected(props.sector),
      resources: []
    }
  }

  componentDidMount () {
    this.renderResources()
  }

  componentDidUpdate (prevProps) {
    if (this.props.isReset) {
      this.setState({
        isSelected: false
      })
    }
    if (this.props.showResources !== prevProps.showResources) {
      this.renderResources()
    }

    if (this.props.selectedResources !== prevProps.selectedResources) {
      this.renderResources()
    }
  }

  onSelect = (sector, e) => {
    this.setState({
      isSelected: !this.state.isSelected
    })
    this.props.onSelect(sector, e)
  }

  renderResources = () => {
    const { sector, onResourceChanged, isResourceSelected } = this.props
    if (sector.resources && sector.resources.length > 0 && this.props.facet > 0 && this.props.showResources) {
      this.setState({
        resources: null
      }, () => {
        this.setState({
          resources: <ul className='resources'>
                      {
                        sector.resources.map(resource => (
                          <li key={resource.id}>
                            <label className='check-resources'><CheckBox onChange={onResourceChanged.bind(this, resource, sector)}
                             onLoad={isResourceSelected.bind(this, resource, sector)}/><span>{resource.name}</span></label>
                          </li>
                        ))
                      }
                    </ul>
        })
      })
      
    } else {
      this.setState({
        resources: null
      })
    }
  }

  render() {
    const { sector } = this.props
    return (
      sector && <div className={this.state.isSelected ? 'sector selected' : 'sector'} key={sector.id} onClick={this.onSelect.bind(this, sector)}>
          <div className='image'>
            <img src={sector.image_display_url} alt={sector.title}/>
          </div>
          <div className='info'>
            <h2 className='heading'>
              {sector.title}
            </h2>
            <p>
            {sector.description}
            </p>
            <div className='badge badge-number'>{this.props.facet}</div>
          </div>
          {
            this.props.facet > 0 && this.state.resources
          }
      </div>
    )
  }
}

SectorItem.propTypes = {
  sector: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default SectorItem