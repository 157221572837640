/**
 * @file defines utility functions for processing user authorizations.
 */

const UserRole = {
  ADMIN: 'admin',
  EDITOR: 'editor',
  MEMBER: 'member'
}

const authzHelper = (userData) => {
  const data = (userData && {...userData}) || {};

  return {
    // properties
    isSysAdmin: data.sysadmin,

    // methods
    canAccessDataset: function(dataset) {
      if (!data.sysadmin && dataset && dataset.private) {
        if (data && data.roles && data.roles.length >= 1) {
          const found = data.roles.filter(r => r.id === dataset.owner_org);
          return (found && found.length >= 1);
        }
        return false;
      }
      return true;
    },

    canManageDataset: function(dataset) {
      if (!data.sysadmin && dataset && dataset.private) {
        return this.canManageStateWithId(dataset.owner_org);
      }
      return true;
    },

    canManageState: function(state) {
      if (!data.sysadmin && state) {
        return this.canManageStateWithId(state.id);
      }
      return true;
    },

    canManageStateWithId: function(stateId) {
      if (!data.sysadmin) {
        if (data && data.roles && data.roles.length >= 1) {
          const targetRoles = [UserRole.EDITOR, UserRole.ADMIN];
          const found = data.roles.filter(r => (
            r.id === stateId && targetRoles.indexOf(r.capacity) !== -1
          ));
          return (found && found.length >= 1);
        }
        return false;
      }
      return true;
    }
  }
}

export default authzHelper;
