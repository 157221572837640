import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { initMap, loadGeojsonInClusters, getMarkerClusterGroup } from '../../../utils/maps'
import { DownloadBar, Loader } from '../../common'

class MapView extends Component {

  constructor(props) {
    super(props);
    this.nigerianMap = React.createRef()
    this.mapDatasetDiv = React.createRef()
    this.mapDatasetDivContainer = React.createRef()
    this.state = {
      isDrawerOpen: false,
      map: null,
      clusterLayer: null,
      geoJsonLayers: {},
      isLoading: false
    }
  }

  componentDidMount () {
    const map = initMap(this.nigerianMap.current, { center: [9.0820, 8.6753], zoom: 6, scrollWheelZoom: false })
    const clusterLayer = getMarkerClusterGroup()
    const layers = this.state.geoJsonLayers
    this.activateLoadingonScroll();
    this.setState({
      map,
      clusterLayer
    }, () => {
      map.addLayer(this.state.clusterLayer)
      if (this.props.mapData) {
        Object.keys(this.props.mapData).forEach(key => {
          this.addNewLayer(layers, this.props.mapData[key], key, clusterLayer, map)
        })
      }
    })
  }

  componentWillUnmount () {
    this.mapDatasetDiv.current.removeEventListener('scroll', this.props.onScroll.bind(this, this.mapDatasetDiv.current))
  }

  componentWillReceiveProps (newProps) {
    const map = this.state.map
    const clusterLayer = this.state.clusterLayer || getMarkerClusterGroup()
    const layers = this.state.geoJsonLayers
    if (newProps.loading) {
      this.showLoader()
    }
    if (!newProps.loading && newProps.mapData && this.props.mapData !== newProps.mapData) {
      // Remove unselected layers
      Object.keys(this.props.mapData || {}).forEach(key => {
        if (!newProps.mapData[key]) {
          clusterLayer.removeLayer(layers[key])
        }
      })

      // All new selected layers
      if (this.props.mapData) {
        Object.keys(newProps.mapData).forEach(key => {
          if (!this.props.mapData[key]) {
            this.addNewLayer(layers, newProps.mapData[key], key, clusterLayer, map)
          }
        })
      } else {
        Object.keys(newProps.mapData).forEach(key => {
          this.addNewLayer(layers, newProps.mapData[key], key, clusterLayer, map)
        })
      }
      this.setState({
        map,
        geoJsonLayers: layers,
        clusterLayer
      }, this.hideLoader())
    }
  }

  activateLoadingonScroll = () =>{
    this.mapDatasetDivContainer.current.addEventListener('scroll', this.props.onScroll.bind(this, this.mapDatasetDiv.current, this.mapDatasetDivContainer.current.getBoundingClientRect().height))
  }

  showLoader = () => {
    this.setState({
      isLoading: true
    })
  }

  hideLoader = () => {
    this.setState({
      isLoading: false
    })
  }

  addNewLayer = (layers, geoData, key, clusterLayer, map) => {
    if (layers[key]) {
      clusterLayer.addLayer(layers[key])
    } else {
      layers[key] = loadGeojsonInClusters(geoData, clusterLayer, map)
    }
  }

  toggleMapDrawer = () => {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen })
    setTimeout(

      function() {
        this.state.map.invalidateSize(true);
        if (this.state.isDrawerOpen) {
          this.activateLoadingonScroll();
        }
      }
      .bind(this),
      400
    )
  }

  render () {
    return (
      <div className={ this.state.isDrawerOpen ? 'show-datasets results': 'results'}>
        <div className='map-container'>
          <div ref={this.nigerianMap} className='map-div'></div>
          <Loader show={this.state.isLoading}/>
        </div>
        <div className='datasets-drawer'> 
          <div className='drawer-toggle' onClick= { this.toggleMapDrawer }>
            { this.props.isListLoading && !this.state.isDrawerOpen ? (<div className='loading-icon'><Loader show={true}/></div>) : <i className='fa icon fa-caret-right'></i>}
          </div>
          <div className='datasets-list-small' ref={this.mapDatasetDivContainer}>
            <div ref={this.mapDatasetDiv}>
            {
              !this.props.isListLoading &&
              (
                <div className='list-title'>found { this.props.datasetCount } { this.props.datasetCount === 1 ? 'dataset' : 'datasets' }</div>
              )
            }
              <ul>
                {this.props.datasetList.length === 0 ? (!this.props.isListLoading && this.props.noResultMessage) : this.props.datasetList}
              </ul>
              {this.props.isListLoading &&
                <div className='loading-container'>
                  <Loader show={true}/>
                </div>
              }
            </div>
          </div>
          {
            this.props.downloadList.length > 0 && <DownloadBar
            downloads={this.props.downloadList}
            formats={this.props.downloadFormats}
            onFormatChanged={this.props.onDownloadFormatChanged}
            downloadResources={this.props.downloadResources}
            />
          }
        </div>
      </div>
    )
  }
}

MapView.propTypes = {
  datasetList: PropTypes.array.isRequired,
  noResultMessage: PropTypes.element.isRequired,
  downloadList: PropTypes.array.isRequired,
  downloadFormats: PropTypes.object.isRequired,
  onDownloadFormatChanged: PropTypes.func.isRequired,
  downloadResources: PropTypes.func.isRequired
}

export default MapView
