import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toastr } from 'react-redux-toastr'
import queryString from 'query-string'

import { PageTemplate, MultiSelect, Search, DatasetItem, Loader } from '../common'
import Sectors from './components/Sectors'
import MapView from './components/MapView'
import ListView from './components/ListView'
import Resource from './components/Resource'
import CheckBox from './components/CheckBox'
import { getData } from '../../api-requests'
import urls from '../../utils/urls'
import authzHelper from '../../utils/auth'
import { dispatchActions } from '../../store/actions/action-config.action'
import { buildSolrQueryString, parseQueryString } from '../../utils/string.utils'

let selectedResourcesByResourceType = {}
class DatasetList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      VIEW: 'list',
      locations: [],
      locationsValue: [],
      tags: [],
      tagsValue: [],
      sectors: [],
      datasetsOffset: 0,
      pageSize: 20,
      completeReturnedDatasetList: [],
      sortOrder: {value: 'name%20asc', label: 'Name Ascending'},
      datasetList: [],
      mapDatasetList: [],
      downloadList: [],
      visualizeData: {},
      filters:{
        sectors: null,
        locations: null,
        tags: null
      },
      searchText: '',
      resetSectors: false,
      downloadsByFormat: {},
      facets: {},
      querySectors: [],
      noResultMessage: <div className='no-results'><span>No datasets found.</span></div>,
      resourcesBySector: {},
      selectedResourcesByResourceType: {}
    }
    this.headerDiv = React.createRef()
    this.locationCodesByResources = {}
  }

  componentDidMount () {
    // pull sectors from api endpoint
    const getSectors = () => (
      getData({ url: urls.sectors, context: 'POST', inputData: { all_fields: true, include_dataset_count: true, include_resources: true }  })
    )
    this.props.dispatchActions('GET_SECTORS', { func: getSectors })

    // pull locations from api endpoint
    const getLocations = () => (
      getData({ url: urls.locations })
    )
    this.props.dispatchActions('GET_LOCATIONS', { func: getLocations })

    // pull tags from api endpoint
    const getTags = () => (
      getData({ url: urls.tags })
    )
    this.props.dispatchActions('GET_TAGS', { func: getTags })

    if (this.props.location.search) {
      const queries = parseQueryString(this.props.location.search)
      const newFilters = {...this.state.filters}
      let sText = this.state.searchText
      let selectedLocations = []
      if (queries['location']) {
        let loc = JSON.parse(queries.location) || []
         selectedLocations = loc.map(l => (
          { value: l, label: l }
        ))
        newFilters.locations = buildSolrQueryString('organization', loc)
      }
      if (queries['sector']) {
        let sec = JSON.parse(queries.sector) || []
        this.setState({
          querySectors: sec.map(s => ({name: s}))
        })
        newFilters.sectors = buildSolrQueryString('groups', sec)
      }
      if (queries['q']) {
        sText = queries.q
      }
      this.setState({
        searchText: sText,
        filters: newFilters,
        locationsValue: selectedLocations
      }, this.resetList(this.getDatasetPage.bind(this, true)))
    } else {
      this.getDatasetPage(true)
    }
  }

  componentWillReceiveProps (newProps) {
    if (newProps.locationsPayload && this.props.locationsPayload !== newProps.locationsPayload) {
      this.buildLocations(newProps.locationsPayload)
    }

    if (newProps.tagsPayload && this.props.tagsPayload !== newProps.tagsPayload) {
      this.buildTags(newProps.tagsPayload)
    }

    if (newProps.datasetsPayload && this.props.datasetsPayload !== newProps.datasetsPayload) {
      this.buildDatasetItems((newProps.datasetsPayload.result && newProps.datasetsPayload.result.results) || [])
    }
  }

  getDatasetPage = (initial=null, fetchAll=false) => {
    // pull datasets from api endpoint
    const {sortOrder, datasetsOffset, pageSize} = this.state
    const query = this.buildFilterString()
    const fq = query !== '' ? `&fq=${query}` : ''
    const searchQuery = this.state.searchText !== '' ? `&q=title:${this.state.searchText}` : ''
    let url = `${urls.allDatasets}&start=${datasetsOffset}&rows=${pageSize}&sort=${sortOrder.value}${searchQuery}${fq}`
    this.setState({
      noResultMessage: this.buildNoResultMessage()
    })
    if (fetchAll) {
      getData({ url: `${urls.allDatasets}&start=0&rows=1${searchQuery}${fq}` })
        .then(res => {
          url = `${urls.allDatasets}&start=0&rows=${res.result.count}&sort=${sortOrder.value}${searchQuery}${fq}`
          this.actualFetch(initial, url)
        })
      return
    }
    if (initial) {
      const locQuery = this.state.filters.locations ? `&fq=${this.state.filters.locations}` : ''
      getData({ url: `${urls.allDatasets}&start=0&rows=1${locQuery}${searchQuery}` })
        .then(res => {
          this.setState({
            facets: (res.result && res.result.facets.groups) || this.state.facets
          })
        })
    }
    this.actualFetch(initial, url)
  }

  actualFetch = (initial, url) => {
    const fetchDatasetsPage = () => {
      return getData({ url })
        .then(res => {
          if (res.result && res.result.results) {
            const appendList = [...this.state.completeReturnedDatasetList]
            res.result.results.forEach(r => {
              appendList.push(r)
            })
            this.setState({
              completeReturnedDatasetList: appendList,
            })
            if (initial && !this.state.filters.locations) {
              this.setState({
                facets: res.result.facets.groups
              })
            }
          }
          return res
        })
    }
    this.props.dispatchActions('GET_ALL_DATASETS', { func: fetchDatasetsPage })
  }

  resetList = (cb) => {
    this.setState({
      completeReturnedDatasetList: [],
      datasetsOffset: 0,
      datasetList: [],
      downloadList: [],
      downloadsByFormat: {},
      mapDatasetList: []
    }, cb)
  }

  clearMap = () => {
    this.props.dispatchActions('MAP_DATA_CHANGED', { func: () => ({}) })
  }

  trackScrolling = (el, containerHeight) => {
    const datasetCount = (
      this.props.datasetsPayload && this.props.datasetsPayload.result &&
      this.props.datasetsPayload.result.count
    ) || 0;

    if (this.state.VIEW === 'map') {
      containerHeight = containerHeight + 140
    }
    if (el.getBoundingClientRect().bottom <= containerHeight) { // check reloading is false then fetch next page
      if (!this.props.datasetsPending && this.state.completeReturnedDatasetList.length < datasetCount) {
        const newOffset = this.state.datasetsOffset + this.state.pageSize
        this.setState({ datasetsOffset: newOffset }, this.getDatasetPage)
      }
    }
  }

  buildLocations = locationsData => {
    const locations = locationsData.result ? locationsData.result.map(location => ({ value: location.name, label: location.title })) : []
    this.setState({
      locations
    })
  }

  buildTags = tagsData => {
    const tags = tagsData.result ? tagsData.result.map(tag => ({ value: tag.name, label: tag.display_name })) : []
    this.setState({
      tags
    })
  }

  buildDatasetItems = datasets => {
    const list = this.state.datasetList;
    const mapdatasets = [...this.state.mapDatasetList];
    const userAuth = authzHelper(this.props.userDataPayload);

    if (datasets.length) {
      list.push(datasets.map(dataset => (
        <DatasetItem dataset={dataset} key={dataset.id} onSelect={this.onSelectedDatasetChanged}>
          { !userAuth.canAccessDataset(dataset)
            ? (
              <ul className='resources'>
                <li>
                  <label className='check-resources-small'>
                    <a href={`/dataset/request_access/${dataset.name}?type=dataset`}>Request access</a>
                  </label>
                </li>
              </ul>
            )
            : (
              dataset.resources.length > 0 ? (<ul className='resources'>
                {dataset.resources.map(resource => (
                  <li key={resource.id}><label className='check-resources-small'>
                    <CheckBox onChange={this.onResourceToggle.bind(this, resource, dataset.title, dataset.id, true)} onLoad={this.isResourceSelected.bind(this, resource, dataset.title, dataset.id, dataset.organization)} /><span>{resource.name}</span></label>
                    <span className='label' data-format={resource.format}>{resource.format}</span>
                  </li>
                ))}
              </ul>) :
              (<div className='resources'>No downloadable resources</div>)
            )
          }
        </DatasetItem>
      )))
      mapdatasets.push(datasets.map(dataset => (
        <Resource dataset={dataset} key={dataset.id} onSelect={this.onSelectedDatasetChanged}>
          { !userAuth.canAccessDataset(dataset)
            ? (
              <li>
                <label className='check-resources-small'>
                  <a href={`/dataset/request_access/${dataset.name}?type=dataset`}>Request access</a>
                </label>
              </li>
            ): (
              dataset.resources.length > 0 && dataset.resources.map(resource => (
                <li key={resource.id}><label className='check-resources-small'>
                  <CheckBox onChange={this.onResourceToggle.bind(this, resource, dataset.title, dataset.id, true)} onLoad={this.isResourceSelected.bind(this, resource, dataset.title, dataset.id, dataset.organization)} /><span>{resource.name}</span></label>
                  <span className='label' data-format={resource.format}>{resource.format}</span>
                </li>
              ))
            )
          }
        </Resource>
      )))
    }

    this.setState({
      datasetList: list,
      mapDatasetList: mapdatasets
    })
  }

  onViewChange = view => {
    const top = this.headerDiv.current.getBoundingClientRect().top + window.scrollY + 20
    this.setState({
      VIEW: view
    })
    window.scrollTo({
      top: top,
      left: 0,
      behavior: 'smooth'
    })
  }

  onSearchChanged = event => {
    this.setState({
      searchText: event.target.value
    }, this.resetList(this.getDatasetPage.bind(this, true)))
  }

  onClearSearchText = () => {
    this.setState({
      searchText: ''
    }, this.resetList(this.getDatasetPage.bind(this, true)))
  }

  onTagFilterChanged = tags => {
    const newFilters = {...this.state.filters}
    if (tags.length) {
      newFilters.tags = buildSolrQueryString('tags',tags.map(tag => (tag.value)))
    } else {
      newFilters.tags = null
    }
    this.setState({
      filters: newFilters,
      tagsValue: tags,
    }, this.resetList(this.getDatasetPage))
  }

  onLocationFilterChanged = locations => {
    const newFilters = {...this.state.filters}
    if (locations.length) {
      newFilters.locations = buildSolrQueryString('organization',locations.map(location => (location.value)))
    } else {
      newFilters.locations = null
    }
    this.clearMap()
    this.setState({
      filters: newFilters,
      locationsValue: locations,
    }, this.resetList(this.getDatasetPage.bind(this, true)))
  }


  onResourceToggle = (resource, title, id, renderOnMap, event) => {
    resource = {...resource, datasetTitle: title, datasetId: id }
    const resourcesByFormat = this.state.downloadsByFormat
    const resourcesBySector = this.state.resourcesBySector


    let formatList = resourcesByFormat[resource.format]
    let resourceTypeList = selectedResourcesByResourceType[resource.name] || []

    if (event.target.checked) {
      if (formatList) {
        const exists = formatList.find(x => x.id === resource.id)
        if (!exists) {
          formatList.push(resource)
        }
      } else {
        formatList = [resource]
      }
      resourcesByFormat[resource.format] = formatList
      selectedResourcesByResourceType[resource.name] = [...resourceTypeList, resource.id]
      this.setState({
        downloadsByFormat: resourcesByFormat,
        selectedResourcesByResourceType
      })
      if (!this.state.downloadList.find(x => x.id === resource.id)) {
        setTimeout(() => {
          this.setState({
            downloadList: [...this.state.downloadList, resource]
          })
        }, 100)
      }
      if (renderOnMap) {
        this.props.dispatchActions('MAP_DATA_CHANGED', { func: this.processMapDataPoints.bind(this, resource) })
      }
    } else {
      if (formatList) {
        const nformatList = formatList.filter(x => x.id !== resource.id)
        if (!nformatList.length) {
          delete resourcesByFormat[resource.format]
        } else {
          resourcesByFormat[resource.format] = nformatList
        }
      }
      if (resourcesBySector[resource.name]) {
        delete resourcesBySector[resource.name]
        this.setState({
          resourcesBySector
        })
      }

      const nresourceTypeList = resourceTypeList.filter(x => x !== resource.id)
      selectedResourcesByResourceType[resource.name] = nresourceTypeList
      this.setState({
        downloadList: this.state.downloadList.filter(x => x.id !== resource.id),
        downloadsByFormat: resourcesByFormat,
        selectedResourcesByResourceType
      })
      this.props.dispatchActions('MAP_DATA_CHANGED', { func: this.removeMapData.bind(this, resource) })
    }
  }

  isResourceSelected = (resource, datasetTitle=null, datasetId=null, organization=null) => {
    const foundResource = this.state.downloadList.find(x => x.id === resource.id)
    if (foundResource) {
      return true
    }

    const rBys = Object.keys(this.state.resourcesBySector)
    let found = false
    for (let i = 0; i < rBys.length; i++) {
      if (rBys[i] === resource.name) {
        found = true
        const organziationCode = (this.props.locationsPayload && this.props.locationsPayload.result && this.props.locationsPayload.result.find(x => x.name === organization.name)['code']) || '';
        if (organziationCode) {
          if (this.locationCodesByResources[resource.name]) {
            this.locationCodesByResources[resource.name].push(organziationCode)
          } else {
            this.locationCodesByResources[resource.name] = [organziationCode]
          }
        }
        this.onResourceToggle(resource, datasetTitle, datasetId, false, { target: { checked: true} })
        break
      }
    }
    return found
  }

  isSectorSelected = sector => {
    const foundSector = this.state.querySectors.find(x => x.name === sector.name)
    if (foundSector) {
      this.setState({
        querySectors: this.state.querySectors.filter(x => x.name !== sector.name),
        sectors: [...this.state.sectors, sector]
      })
      return true
    }
    return false
  }

  getStateCodes = resourceName => {
    const locations = this.locationCodesByResources[resourceName]
    let uniqueLocattions = ''
    if (locations && locations.length)
      uniqueLocattions = JSON.stringify([... new Set(locations)]).replace('[', '(').replace(']', ')').replace(/"/g, "'")
    return uniqueLocattions
  }

  processMapDataPoints = (resource, isGrouped=false) => {
    const nDataVisuals = this.props.mapDataPayload ? {...this.props.mapDataPayload} : {}
    if (this.state.visualizeData[resource.id]) {
      nDataVisuals[resource.id] = this.state.visualizeData[resource.id]
      return nDataVisuals
    }

    const format = resource.format.toLowerCase()
    if (format === 'geojson' || format === 'csv') {
      let res_data_url = resource.url
      const output_fmt_entry = 'outputFormat=csv'
      if (format === 'csv') {
        if (res_data_url.indexOf(output_fmt_entry) !== -1) {
          res_data_url = res_data_url.replace(output_fmt_entry, 'outputFormat=application/json');
        }
      }
      if (isGrouped) {
        const urlParts = res_data_url.split('?')
        // TODO: Layers not shown problem comes from here CQL FIlter is not parsed correctly ;
        // ISSUE https://github.com/ciesin-geospatial/GRID3_NGA_Portal/issues/4
        const qs = queryString.parse(urlParts[1], {decode: true})
        const delay = (t, v) => {
          return new Promise(resolve => {
              setTimeout(resolve.bind(null, v), t)
          })
       }

       return delay(2000)
       .then(() => {
          const CQL = this.getStateCodes(resource.name)
          const CQLFilter = CQL ? `state_code IN ${CQL}` : ''
          const CQLCombine = CQLFilter ? ` AND ${CQLFilter}` : ''
          qs['CQL_FILTER'] = qs['CQL_FILTER'] ? `${qs['CQL_FILTER']}${CQLCombine}`
          : CQLFilter
          const finalQS = queryString.stringify(qs)
          res_data_url = `${urlParts[0]}?${finalQS}`
          return this.getMapPoints(res_data_url, nDataVisuals, resource)
       })
      } else {
        return this.getMapPoints(res_data_url, nDataVisuals, resource)
      }
    }
  }

  getMapPoints = (url, nDataVisuals, resource) => {
    return getData({ url })
      .then(res => {
        const cachedMapData = {...this.state.visualizeData}
        cachedMapData[resource.id] = res
        nDataVisuals[resource.id] = res
        this.setState({
          visualizeData: cachedMapData
        })
        return nDataVisuals
      })
    }

  removeMapData = (resource, isGroup=false) => {
    const nDataVisuals = this.props.mapDataPayload ? {...this.props.mapDataPayload} : {}
    delete nDataVisuals[resource.id]
    if (isGroup) {
      const sRbySector = this.state.selectedResourcesByResourceType[resource.name]
      if (sRbySector.length) {
        sRbySector.forEach(x => {
          delete nDataVisuals[x]
        })
      }
    }
    return nDataVisuals
  }

  isResourceChecked = resourceId => {
    if (this.state.downloadList.length) {
      const foundResource = this.state.downloadList.find(x => x.id === resourceId)
      if (foundResource) {
        return true
      }
    }
    return false
  }

  onDownloadFormatChanged = (format, e) => {
    let downloadList = this.state.downloadList
    const selectedFormat = this.state.downloadsByFormat[format]
    if (e.target.checked && selectedFormat) {
      selectedFormat.forEach(downloadable => {
        if (!downloadList.find(x => x.id === downloadable.id)) {
          downloadList.push(downloadable)
        }
      })
    } else if(selectedFormat) {
      selectedFormat.forEach(downloadable => {
        downloadList = downloadList.filter(x => x.id !== downloadable.id)
      })
    }
    this.setState({
      downloadList
    })
  }

  downloadResources = options => {
    const resources = this.state.downloadList.map(download => {
      if (options.geoJsonFormat && download.format.toLowerCase() === 'geojson') {
        return { id: download.id, format: options.geoJsonFormat }
      } else {
        return { id: download.id }
      }
    })
    getData({ url: urls.prepareDownloads, context: 'POST', inputData: { resources, include_metadata: options.include_metadata } })
      .then(res => {
        if (res.result && res.result.file_id) {
          // download zip file by id
          window.location.href = `${urls.zippedResourcesDownload}${res.result.file_id}`
        } else {
          // Resource(s) were not archived
          toastr.warning('Download Error', 'Encountered an error archiving resources')
        }
      })
      .catch(err => {
        // show download error
        toastr.error('Download Error', `${err.toString()}`)
      })
  }

  onSortChange = sortOrder => {
    this.setState({ sortOrder }, this.resetList(this.getDatasetPage))
  }

  onResourceChanged = (resource, sector, event) => {
    const rBySectors = this.state.resourcesBySector
    const newResource = {...resource, sector: sector.name, id: `${resource.id}-group`}
    if (event.target.checked) {
      rBySectors[resource.name] = newResource
      this.props.dispatchActions('MAP_DATA_CHANGED', { func: this.processMapDataPoints.bind(this, newResource, true) })
    } else {
      delete rBySectors[resource.name]
      this.props.dispatchActions('MAP_DATA_CHANGED', { func: this.removeMapData.bind(this, newResource, true) })
    }
    this.setState({
      resourcesBySector: rBySectors
    }, this.resetList(this.getDatasetPage(false, true)))
  }

  isResourceGroupSelected = (resource, sector) => {
    const newResource = {...resource, sector: sector.name, id: `${resource.id}-group`}
    if (this.state.resourcesBySector && this.state.resourcesBySector[resource.name]) {
      this.props.dispatchActions('MAP_DATA_CHANGED', { func: this.processMapDataPoints.bind(this, newResource, true) })
      return true
    }
    return false
  }

  onSectorSelect = (sector, e) => {
    if (e.target.tagName.toLowerCase() !== 'input' && e.target.tagName.toLowerCase() !== 'span') {
      const selectedSector = this.state.sectors.find(x => x.name === sector.name)
      if (selectedSector) {
        this.setState({
          sectors: this.state.sectors.filter(x => x.name !== selectedSector.name)
        }, this.sectorsChangeCallback)
      } else {
        this.setState({
          sectors: [...this.state.sectors, sector]
        }, this.sectorsChangeCallback)
      }
    }
  }

  sectorsChangeCallback = () => {
    const newFilters = {...this.state.filters}
    if (this.state.sectors.length) {
      newFilters.sectors = buildSolrQueryString('groups',this.state.sectors.map(sector => (sector.name)))
    } else {
      newFilters.sectors = null
    }

    this.setState({
      filters: newFilters,
    }, this.resetList(this.getDatasetPage))
  }

  onSelectedDatasetChanged = dataset => {
    this.props.dispatchActions('SELECTED_DATASET_CHANGED', { func: () => (dataset) })
  }

  buildFilterString = () => {
    const { filters } = this.state
    let qs = ''
    Object.keys(filters).forEach(key => {
      if (filters[key]) {
        if (qs !== '') {
          qs = `${qs} AND ${filters[key]}`
        } else {
          qs = qs = `${filters[key]}`
        }
      }
    })
    return qs
  }

  buildNoResultMessage = () => {
    const msg = (<div className='no-results'>
      <span>No datasets for </span>
      {this.state.locationsValue.length > 0 && <span className='em'>{this.state.locationsValue.map((location, idx, array) => {
        if (idx === array.length -1){
          return location.label
        } else {
          return `${location.label}, `
        }
      })}</span>}
      {this.state.locationsValue.length > 0 && (this.state.sectors.length > 0 || this.state.tagsValue.length > 0) && <span> and </span>}
      {this.state.sectors.length > 0 && <span className='em'>{this.state.sectors.map((sector, idx, array) => {
        if (idx === array.length -1){
          return sector.display_name
        } else {
          return `${sector.display_name}, `
        }
      })}</span>}
      {this.state.sectors.length > 0 && this.state.tagsValue.length > 0 && <span> and </span>}
      {this.state.tagsValue.length > 0 && <span className='em'>{this.state.tagsValue.map((tag, idx, array) => {
        if (idx === array.length -1){
          return tag.label
        } else {
          return `${tag.label}, `
        }
      })}</span>}
      {(this.state.locationsValue.length > 0 || this.state.sectors.length > 0 || this.state.tagsValue.length > 0) && this.state.searchText !== '' && <span> with </span>}
      {this.state.searchText !== '' && <span className='em quote'>{this.state.searchText}</span>}
    </div>)
    return msg
  }

  render () {
    const sectors = (this.props.sectorsPayload && this.props.sectorsPayload.result) || [];
    const sectorLoaded = !this.props.sectorsPayload
    const datasetCount = (this.props.datasetsPayload && this.props.datasetsPayload.result && this.props.datasetsPayload.result.count) || 0;
    const { datasetsPending } = this.props;
    return(
      <PageTemplate path={this.props.match.path}>
        <div role='main' className='map-search-content'>
          <div className={this.state.VIEW === 'map' ? 'map-search map-view' : 'map-search list-view'}>
            <div className='map-search_header' ref={this.headerDiv}>
              <div className='map-search_tabs'>
                <div className={this.state.VIEW === 'list' ? 'tab active': 'tab'} onClick={this.onViewChange.bind(this, 'list')}>
                  <i className='fa icon fa-list'></i>List
                </div>
                <div className={this.state.VIEW === 'map' ? 'tab active': 'tab'} onClick={this.onViewChange.bind(this, 'map')}>
                  <i className='fa icon fa-map-marker-alt leaflet-drag-target'></i>Map
                </div>
              </div>
              <h2 className='dataset-count'>found <b>{ !datasetsPending && datasetCount }</b> { !datasetsPending && (datasetCount === 1 ? 'dataset' : 'datasets') }</h2>
            </div>
            <div className='map-search_container'>
            <section className='map-search_sectors'>
              <div className='top-bar'>Sectors</div>
                <div className='sectors'>
                  <div className='loading-container'>
                    <Loader show={sectorLoaded}/>
                  </div>
                  <Sectors
                    view={this.state.VIEW}
                    data={sectors}
                    onSelect={this.onSectorSelect}
                    isReset={this.state.resetSectors}
                    facets={this.state.facets}
                    isSelected={this.isSectorSelected}
                    onResourceChanged={this.onResourceChanged}
                    isResourceSelected={this.isResourceGroupSelected}
                    selectedResources={this.state.resourcesBySector}
                  />
                </div>
            </section>
            <section className='map-search_results'>
              <div className='top-bar'>
                <div className='select-location filter'>
                  <span className='filter-label'>Locations:</span>
                  <MultiSelect
                    isMulti
                    options={this.state.locations}
                    hideSelectedOptions={true}
                    placeholder='All'
                    isClearable={false}
                    onChange={this.onLocationFilterChanged}
                    value={this.state.locationsValue}
                  />
                </div>
                <Search placeholder='Search datasets...' onChange={this.onSearchChanged} value={this.state.searchText} onClearText={this.onClearSearchText}/>
              </div>
              {this.state.VIEW === 'map' &&
              <MapView
                datasetList={this.state.mapDatasetList}
                datasetCount={datasetCount}
                downloadList={this.state.downloadList}
                onScroll={this.trackScrolling}
                mapData={this.props.mapDataPayload}
                loading={this.props.mapDataPending}
                isListLoading={this.props.datasetsPending}
                downloadFormats={this.state.downloadsByFormat}
                onDownloadFormatChanged={this.onDownloadFormatChanged}
                downloadResources={this.downloadResources}
                noResultMessage={this.state.noResultMessage}
              />}
              {this.state.VIEW === 'list' &&
                <ListView
                  datasetList={this.state.datasetList}
                  onSortChange={this.onSortChange}
                  sortValue={this.state.sortOrder}
                  downloadList={this.state.downloadList}
                  noResultMessage={this.state.noResultMessage}
                  isLoading={this.props.datasetsPending}
                  downloadFormats={this.state.downloadsByFormat}
                  onDownloadFormatChanged={this.onDownloadFormatChanged}
                  onScroll={this.trackScrolling}
                  downloadResources={this.downloadResources}
                />
              }
            </section>
          </div>
          </div>
        </div>
      </PageTemplate>
    )
  }
}

const mapStateToProps = ({
  getTags,
  getSectors,
  setUserData,
  getLocations,
  getAllDatasets,
  mapDataChanged
}) => ({
  userDataPending: setUserData.pending,
  userDataPayload: setUserData.payload,
  userDataError: setUserData.error,
  sectorsPending: getSectors.pending,
  sectorsPayload: getSectors.payload,
  sectorsError: getSectors.error,
  locationsPending: getLocations.pending,
  locationsPayload: getLocations.payload,
  locationsError: getLocations.error,
  tagsPending: getTags.pending,
  tagsPayload: getTags.payload,
  tagsError: getTags.error,
  datasetsPending: getAllDatasets.pending,
  datasetsPayload: getAllDatasets.payload,
  datasetsError: getAllDatasets.error,
  mapDataPending: mapDataChanged.pending,
  mapDataPayload: mapDataChanged.payload,
  mapDataError: mapDataChanged.error,
})

const mapDispatchToProps = dispatch => (
  bindActionCreators({ dispatchActions }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(DatasetList)
