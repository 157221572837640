import * as React from "react";
import moment from "moment";
import { convertToTitleCase } from '../../utils/string.utils';


const Metadata = ({ type, dataObject, excludeList, wrap }) => {
  // the exclude list contains fields that have already been displayed
  // or are meant for interna use and shouldn't be displayed as metadata
  excludeList = (excludeList || []);
  wrap = wrap || false;

  const toTitleCase = (value) => (
    convertToTitleCase(
      value.replace(/_/g, ' ').replace(/-/g, ' ')
    )
  )

  const getMetadataKeys = () => {
    let keys = Object.keys(dataObject).filter((val, idx, arr) => {
      if (excludeList.indexOf(val) === -1) {
        let letThrough = (
          !val.endsWith('_id') && !val.endsWith('_org') &&
          !val.startsWith('num_') && !val.startsWith('relationships_as_')
        )
        return letThrough;
      }
      return false;
    });
    return keys;
  }

  const isDateField = (name) => {
    return (
      name.endsWith('_created') || name.endsWith('_modified') || name.endsWith('-date')
    )
  }

  const showValue = (key, value) => {
    if (!(typeof value === 'object' && value !== null)) {
      return value;
    }
    return JSON.stringify(value);
  }

  const flattenExtras = extras => {
    if (!extras) return null;
    const extrasObj = {};
    extras.forEach(e => extrasObj[e.key] = e.value);
    return extrasObj;
  }

  const renderItem = (obj, key, idx, label='') => (
    <li key={`${label}${idx}`}>
      <label className="capitlize">{toTitleCase(key)}</label>
      <p>
        { isDateField(key)
          ? moment(obj[key]).format('DD MMMM, YYYY')
          : showValue(key, obj[key])
        }
      </p>
    </li>
  )

  const metadataKeys = getMetadataKeys().sort();
  const extrasObject = flattenExtras(dataObject['extras']);

  return (
    <div className={wrap && `tab-content`}>
      <section className="metadata">
        <h2 className="title-l">{toTitleCase(type)} Information</h2>
        <ul className="metadata-list">
        { metadataKeys
            .filter(key => key !== 'extras' && dataObject[key])
            .map((key, idx) => renderItem(dataObject, key, idx))
        }
        { extrasObject && (
          <li key="extras_top">
            <label className="capitlize">*** Extras ***</label><p></p>
          </li>
          )
        }
        { extrasObject &&
          Object.keys(extrasObject).sort()
            .filter(key => extrasObject[key])
            .map((key, idx) => renderItem(extrasObject, key, idx, 'extras_'))
        }
        </ul>
      </section>
    </div>
  );
};

export default Metadata;
