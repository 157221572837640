import React, { Component } from 'react'
import { connect } from 'react-redux'

import PageTemplate from './common/PageTemplate'

class Support extends Component {
  render () {
    return(
      <PageTemplate path={this.props.match.path}>
        <div className='container-fluid'>
          <div className='image-header'>
            <div className='bg-image help-desk'>
              <img src='/image/support-bg.jpg' alt='support'></img>
            </div>
            <h1 className='text-center support-headline'>Support</h1>
            {/*<h2 className='support-number'><i className='fa fa-phone'></i>0908 739 5330</h2>*/}
          </div>
          {/*<div className='row-fluid'>*/}
          {/*  <div className='span10 offset1'>*/}
          {/*    <div className='module support-center'>*/}
          {/*      <div className='module-heading'>*/}
          {/*        <h2 className='headline-xxl text-center'>*/}
          {/*          Support Cases*/}
          {/*        </h2>*/}
          {/*      </div>*/}
          {/*      <div className='module-content flex'>*/}
          {/*        <div className='support-info'>*/}
          {/*        <p className='heading-l'>*/}
          {/*          The 24/7 365 Help Desk and Support Center makes it easy for you to find the right information you need. If you need help accessing any of the datasets on the portal or experience any technical issues accessing the portal kindly contact the help desk via the <b>toll-free number 0908-739-5330</b> or via email by filling the <a href='#'>Request Support</a> form.*/}
          {/*        </p>*/}
          {/*        </div>*/}
          {/*        <div className='teaser support-cases'>*/}
          {/*          <a className='teaser-heading' href='#'><h3 className='heading-l'>Support Cases</h3><i className='icon-linkto'></i></a>*/}
          {/*          <div className='support-case'>*/}
          {/*            <a className='support-case-title'><i className='fa fa-envelope-open'></i>Case number one</a>*/}
          {/*            <p className='support-case-date'>created:12 October, 2018</p>*/}
          {/*          </div>*/}
          {/*          <div className='support-case'>*/}
          {/*            <a className='support-case-title'><i className='fa fa-envelope-open'></i>Case number two</a>*/}
          {/*            <p className='support-case-date'>created:12 October, 2018</p>*/}
          {/*          </div>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className='divider'><div className='btn btn-primary'>Request Support</div></div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className='row-fluid'>
            <div className='span10 offset1'>
              <div className='module faq'>
                <div className='module-heading'>
                  <h2 className='headline-xxl text-center'>
                    FAQ
                  </h2>
                </div>
                <div className='module-content'>
                  <div>
                    <h3 className='title-xl'>What is the portal about?</h3>
                    <p className='text-m'>
                      The portal is a repository for data collected across Nigeria, providing robust analysis, and effective data storage, access and archiving means. This is developed to serve the data access, routine analysis and informed decision making needs of government, private sector and donor partners in Nigeria.
                    </p>
                  </div>
                  <div>
                    <h3 className='title-xl'>What data can I find here?</h3>
                    <p className='text-m'>
                      The GRID<sup>3</sup> Data Portal contains a wide range of data sets and outputs generated from the same data sets. We are continually sourcing, updating and publishing data through State, Federal, NGO, donor and private sector sources.
                    </p>
                  </div>
                  <div>
                    <h3 className='title-xl'>In what format is the data available?</h3>
                    <p className='text-m'>
                      Datasets are available in the following formats:
                      JSON, CSV, Geopackage, Spatialite, GML and SHP
                    </p>
                  </div>
                  <div>
                    <h3 className='title-xl'>How frequently is the data updated?</h3>
                    <p className='text-m'>
                      Portal data is updated from a variety of sources whenever new data is available from state, federal, NGO, donor or private sector led campaigns and interventions.
                    </p>
                  </div>
                  <div>
                    <h3 className='title-xl'>How do I find data?</h3>
                    <p className='text-m'>
                      Use the “Search” box at the top of the homepage- Enter keywords to search for specific datasets. You can also search by State. Other ways to achieve this can be found in the Portal User Manual.
                    </p>
                  </div>
                  <div>
                    <h3 className='title-xl'>Can I use the data for academic research or business?</h3>
                    <p className='text-m'>
                      Secondary use of GRID<sup>3</sup> datasets by independent parties such as academic researchers, business executives etc. does not require formal request. Data uploaded to the Portal from external partners however, may be held privately and may require formal request for access.
                    </p>
                  </div>
                  <div>
                    <h3 className='title-xl'>Can I request access to data for which I don’t have access rights?</h3>
                    <p className='text-m'>
                      If you have searched our datasets and don’t have access to your dataset of interest, you can use the “Request Access” feature on the dataset of interest page. The relevant administrator will assess your request and determine if you as a requester has fulfilled all criteria needed to obtain access to the data and if it appropriate to publish such data.
                    </p>
                  </div>
                  {/*<div>*/}
                  {/*  <h3 className='title-xl'>Where can I find the User Manual GRID<sup>3</sup> Data Portal?</h3>*/}
                  {/*  <p className='text-m'>*/}
                  {/*    The GRID<sup>3</sup> Data Portal User Manual can be found <a href='#'>here</a>.*/}
                  {/*  </p>*/}
                  {/*</div>*/}
                  {/*<div>*/}
                  {/*  <h3 className='title-xl'>Is there any risk that personal information will be published?</h3>*/}
                  {/*  <p className='text-m'>*/}
                  {/*    To find out more about our privacy practices, please refer to our Privacy Policy*/}
                  {/*  </p>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageTemplate>
    )
  }
}

export default connect()(Support)
