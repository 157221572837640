import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { SectorItem } from '../../common'

class Sectors extends Component {

  getSectorResouces = sector => {
    const { selectedResources } = this.props
    const resourceKeys = Object.keys(selectedResources)
    let foundResouce = null
    for (let i = 0; i < resourceKeys.length; i++) {
      if (selectedResources[resourceKeys[i]]['sector'] === sector.name) {
        foundResouce = selectedResources[resourceKeys[i]]
      }
    }
    return foundResouce
  }

  render () {
    return(
      this.props.data.map(sector => (
        <SectorItem
          sector={sector}
          key={sector.id}
          showResources={this.props.view && this.props.view === 'map'}
          onSelect={this.props.onSelect}
          isReset={this.props.isReset}
          facet={this.props.facets[sector.name] || 0}
          isSelected={this.props.isSelected}
          onResourceChanged={this.props.onResourceChanged}
          isResourceSelected={this.props.isResourceSelected}
          selectedResources={this.getSectorResouces(sector)}
        />
      ))
    )
  }
}

Sectors.propTypes = {
  view: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  onSelect: PropTypes.func.isRequired,
  isReset: PropTypes.bool.isRequired,
  facets: PropTypes.object.isRequired,
  isSelected: PropTypes.func.isRequired,
  onResourceChanged: PropTypes.func.isRequired,
  isResourceSelected: PropTypes.func.isRequired
}

export default Sectors