import React from 'react'
import PropTypes from 'prop-types'

const Loader = ({ show, customStyle }) => {
  return (
    <div className={show ? 'loading-overlay' : 'loading-overlay hide'} style={customStyle}>
      <div className='loading-spinner'>
        <div className='dot1'></div>
        <div className='dot2'></div>
        <div className='dot3'></div>
        <div className='dot4'></div>
        <div className='dot5'></div>
        <div className='dot6'></div>
      </div>
    </div>
  )
}

Loader.propTypes = {
  show: PropTypes.bool.isRequired
}

export default Loader
