import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReactTable from 'react-table';

import { dispatchActions } from '../../../store/actions/action-config.action';
import { getData } from '../../../api-requests';
import { Loader } from '../../common';
import queryString from 'query-string';

const loaderStyle = { background:'transparent', position:'relative', height:'500px' };


const NullViewer = (sourceUrl) => {
  return (
    <div className="no-results">
      Resource not found or cannot be viewed.
    </div>
  )
}


const ImageViewer = (sourceUrl) => {
  return (
    <div className="module module-image" style={loaderStyle}>
      <img src={sourceUrl} alt="Image Resource" />
    </div>
  )
}


class GeoJSONViewer extends React.Component {

  constructor(props) {
    super(props);
    this.defaultPageSize = 50;
    this.pageSizeOptions = [25, 50, 100, 200, 300];
    this.state = {
      page: 0,
      data: [],
      headers: [],
      rowCount: 0,
      loading: false,
      pageSize: this.defaultPageSize
    }
  }

  componentDidMount() {
    const url = this.buildPagedUrl(this.state);
    const proxyFetch = () => {
      return getData({ url });
    }
    this.props.dispatchActions("GET_RESOURCE_DATA", {func: proxyFetch});
  }

  componentDidUpdate(prevProps, prevState) {
    const props = this.props;
    const state = this.state;
    if (prevProps.dataPending && props.dataPayload) {
      const features = (props.dataPayload.features || []);
      if (features.length > 0) {
        this.setState({
          ...this.prepareTable(features),
          rowCount: props.dataPayload.totalFeatures
        })
      }
    }
  }

  buildPagedUrl = ({ page, pageSize }) => {
    const { sourceUrl } = this.props;
    const qs = queryString.parseUrl(sourceUrl || "").query;
    if (!qs || !('service' in qs) || qs['service'] !== 'WFS') {
      return sourceUrl;
    }

    const limitKey = qs.version && qs.version.startsWith('1')
                   ? 'maxFeatures'
                   : 'count';

    const offset = (page * pageSize) + 1;
    const pgQS = queryString.stringify({
      [limitKey]: pageSize,
      sortBy: 'global_id',
      startIndex: offset
    })

    console.log(`(${page}) ... ${pgQS}`);
    return `${sourceUrl}&${pgQS}`;
  }

  prepareTable = (features) => {
    const row = features[0];
    const headers = Object.keys(row.properties || {}).sort().map(h => (
      {Header: h, accessor: `properties.${h}`}
    ))

    return {headers, data: features}
  }

  onFetchData = (state, instance) => {
    if (instance.state.page === this.state.page && instance.state.pageSize === this.state.pageSize) {
      return;
    }

    this.setState({ loading: true });
    getData({ url: this.buildPagedUrl(instance.state) })
      .then(resp => {
        if (resp && resp.features) {
          this.setState({
            ...this.prepareTable(resp.features),
            rowCount: resp.totalFeatures,
            loading: false
          });
        }
      });
  }

  onPageChange = (pgIndex) => {
    this.setState({ page: pgIndex });
  }

  onPageSizeChange = (pgSize, pgIndex) => {
    this.setState({ pageSize: pgSize, page: pgIndex });
  }

  render() {
    const { dataPending } = this.props;
    let pageCount = Math.floor(this.state.rowCount / this.state.pageSize);

    if ((this.state.rowCount % this.state.pageSize) > 0) {
      pageCount += 1;
    }

    return (
      dataPending
      ? <Loader show={true} customStyle={loaderStyle} />
      : <ReactTable
          defaultPageSize={this.defaultPageSize}
          pageSizeOptions={this.pageSizeOptions}
          minRows={0}
          loading={this.state.loading}
          columns={this.state.headers}
          data={this.state.data}
          page={this.state.page}
          pages={pageCount}
          onFetchData={(state, instance) => this.onFetchData(state, instance)}
          onPageChange={(pgIndex) => this.onPageChange(pgIndex)}
          onPageSizeChange={(pgSize, pgIndex) => this.onPageSizeChange(pgSize, pgIndex)}
          manual />
    )
  }
}


const mapStateToProps = ({ getResourceData }) => {
  return {
    dataPending: getResourceData.pending,
    dataPayload: getResourceData.payload,
    dataError: getResourceData.error,
  }
}


const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatchActions }, dispatch);


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GeoJSONViewer));

export { ImageViewer, NullViewer };
