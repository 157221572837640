// Third party imports
import Proptypes from 'prop-types'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { dispatchActions } from "../../store/actions/action-config.action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";


class Header extends Component {
  constructor (props) {
    super(props)
    this.navDiv = React.createRef()
    this.state = {
      isCollapsed: false
    }
  }

  componentDidMount() {
    if (this.props.isHome) {
      document.addEventListener('scroll', this.trackScrolling);
    }
  }

  componentWillUnmount() {
    if (this.props.isHome) {
      document.removeEventListener('scroll', this.trackScrolling);
    }
  }

  trackScrolling = () => {
    const nav = this.navDiv.current

    if (this.state.isCollapsed === false) {
      if (nav.getBoundingClientRect().top <= -20) { // check if page started to scroll
        this.setState({ isCollapsed: true })
      }
    }

    if (this.state.isCollapsed === true) {
      if (nav.getBoundingClientRect().top > -10) { // check if page is almost scrolltop
        this.setState({ isCollapsed: false })
      }
    }
  }

  scroll = () => {
    const nav = this.navDiv.current

    if (this.state.isCollapsed === false) {
      window.scrollTo({
        top: 100,
        left: 0,
        behavior: 'smooth'
      })
    }
    if (this.state.isCollapsed === true) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }
  }

  renderAuthLinks = () => {
    const userData = this.props.userDataPayload;
    const isSysAdmin = (userData && userData.sysadmin);
    const isStateAdmin = () => {
      const data = (userData && userData.state_admin) || [];
      return (data.length > 0);
    }
    const gravatar = () => {
      let style = {display: "inline-block"};
      let html = (userData && userData.gravatar) || "";
      return <div style={style} dangerouslySetInnerHTML={{__html: html.replace(/100/g, 22) }} />
    };

    if (!userData || Object.keys(userData).length === 0) {
      return (
        <ul className='unstyled'>
          <li><a href='/user/login'>Log in</a></li>
          <li><a className='sub' href='/user/register'>Register</a></li>
        </ul>
      )
    } else {
      return (
        <ul className="unstyled">
        { isSysAdmin &&
          <li>
            <a href="/ckan-admin" title="Sysadmin settings">
              <i className="fa fa-gavel" aria-hidden="true"></i>
              <span className="text">Admin</span>
            </a>
          </li>
        }
          <li>
            <a href={`/user/${userData.name}`} className="image" title="View profile">
              {gravatar()}
              <span className="username">{userData.fullname}</span>
            </a>
          </li>
          <li className={`notifications ${userData.total_new_activities && "notifications-important"}`}>
            <a href="/dashboard" title={`Dashboard (${userData.total_new_activities} new items)`}>
              <i className="fas fa-tachometer-alt" aria-hidden="true"></i>
              <span className="text">Dashboard</span>
              <span className="badge">{userData.total_new_activities || 0}</span>
            </a>
          </li>
        { (isSysAdmin || isStateAdmin()) &&
          <li className={`notifications ${userData.total_pending_requests && "notifications-important"}`}>
            <a href="/requests/pending" title="View Pending Access Requests">
              <i className="fa fa-file" aria-hidden="true"></i>
              <span className="text">Access Requests</span>
              <span className="badge">{userData.total_pending_requests || 0}</span>
            </a>
          </li>
        }
          <li className="notifications ">
            <a href="/notifications/unread" title="You have unread notifications">
              <i className="fa fa-bell" aria-hidden="true"></i>
              <span className="text">Notifications</span>
              <span className="badge">{userData.total_unread_notices || 0}</span>
            </a>
          </li>
          <li>
            <a href={`/user/edit/${userData.name}`} title="Edit settings">
              <i className="fa fa-cog" aria-hidden="true"></i>
              <span className="text">Settings</span>
            </a>
          </li>
          <li>
            <a href="/user/_logout" title="Log out">
              <i className="fas fa-sign-out-alt" aria-hidden="true"></i>
              <span className="text">Log out</span>
            </a>
          </li>
        </ul>
      )
    }
  }

  render() {
    const userData = this.props.userDataPayload || {};

    return (

    this.props.isHome ?
    (
      // Header used on the home page => pass isHome to the PageTemplate component to activate
      <div
        className={this.state.isCollapsed ? 'home-nav collapsed' : 'home-nav'} ref={this.navDiv}>
        <div className='bg-image'><img src='/image/home-bg.jpg' alt='support' /></div>
        <header className='account-masthead'>
          <div className='container-fluid'>
            <nav className={`account ${!userData? "not-authed": "authed"}`}>
              {this.renderAuthLinks()}
            </nav>
          </div>
        </header>
        <header className='header-banner'>
          <div className='government-logo'><img src='/image/nigerian-government-logo.png' alt='Federal Republic of nigeria' /></div>
          <img src='/image/GRID3-nigeria-logo.png' className='grid-nigeria-logo' alt='GRID' title='GRID' />
          <h1 className='banner-title'>Putting everyone on the map with the power of spatial data</h1>
        </header>
        <header className='navbar navbar-static-top masthead'>
          <div className='container-fluid'>
            <hgroup className='header-image'>
              <a className='logo' href='/'><img src='/image/GRID3-nigeria-logo.png' alt='GRID3 Nigeria' title='GRID3' /></a>
            </hgroup>
            <div className='header-search'>
              <form onSubmit={this.props.onSearch}>
                <input type='text' placeholder='Search all datasets…' onChange={this.props.onSearchChanged} value={this.props.searchText}/>
                <i className='fa fa-search'></i>
              </form>
            </div>
            <button data-target=".nav-collapse" data-toggle="collapse" className="btn btn-navbar" type="button">
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <div className='nav-collapse collapse'>
              <nav className='section navigation'>
                <ul className='nav nav-pills'>
                  <li><a href='/national'>National Datasets</a></li>
                  <li><Link to='/datasets'>Datasets</Link></li>
                  <li><Link to='/states'>States</Link></li>
                  <li><a href='/sectors'>Sectors</a></li>
                  <li><Link to='/about'>About</Link></li>
                  <li><Link to='/support'>Support</Link></li>
                </ul>
              </nav>
            </div>
          </div>
        </header>
        <div onClick={this.scroll} className='scroll-down'></div>
      </div>
    )
    :
    (
      // Header used on the other pages other than the home page
      <div>
        <header className='account-masthead'>
          <div className='container-fluid'>
            <nav className={`account ${!userData? "not-authed": "authed"}`}>
              {this.renderAuthLinks()}
            </nav>
          </div>
        </header>
        <header className='navbar navbar-static-top masthead'>
          <div className='container-fluid'>
            <hgroup className='header-image'>
              <a className='logo' href='/'><img src='/image/GRID3-nigeria-logo.png' alt='GRID' title='GRID' /></a>
            </hgroup>
            <button data-target=".nav-collapse" data-toggle="collapse" className="btn btn-navbar" type="button">
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <div className='nav-collapse collapse'>
              <nav className='section navigation'>
                <ul className='nav nav-pills'>
                  <li><Link to='/'>Home</Link></li>
                  <li><Link to='/national'>National Datasets</Link></li>
                  <li className={this.props.path === '/datasets'? 'active': ''}><Link to='/datasets'>Datasets</Link></li>
                  <li className={this.props.path === '/states'? 'active': ''}><Link to='/states'>States</Link></li>
                  <li className={this.props.path === '/sectors'? 'active': ''}><Link to='/sectors'>Sectors</Link></li>
                  <li className={this.props.path === '/about' || this.props.path === '/partner'? 'active': ''}><Link to='/about'>About</Link></li>
                  <li className={this.props.path === '/support'? 'active': ''}><Link to='/support'>Support</Link></li>
                </ul>
              </nav>
            </div>
          </div>
        </header>
      </div>
    )

    )
  }
}


Header.prototypes = {
  isHome: Proptypes.bool
}

const mapStateToProps = ({ setUserData }) => ({
  userDataPending: setUserData.pending,
  userDataPayload: setUserData.payload,
  userDataError: setUserData.error
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatchActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
