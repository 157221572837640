const geoserverKey = "fdfe9a37-d2d0-4210-9a15-25dab5d907fa" // process.env.GEOSERVER_AUTHKEY
export const baseUrl = process.env.REACT_APP_API_DOMAIN || ''
const urls = {
  sectors: `${baseUrl}/api/3/action/group_list?all_fields=true&include_dataset_count=true`,
  locations: `${baseUrl}/api/3/action/organization_list?all_fields=true&include_extras=true`,
  tags: `${baseUrl}/api/3/action/tag_list?all_fields=true&include_extras=true`,
  allDatasets: `${baseUrl}/api/3/action/package_search?include_private=true&facet.field=["groups", "organization"]`,
  homeMap: `${baseUrl}/api/action/eoc_get_map_data?type=country`,
  state: `${baseUrl}/api/3/action/organization_show`,
  unitSector: `${baseUrl}/api/3/action/group_show`,
  sector: `${baseUrl}/api/3/action/group_list`,
  prepareDownloads: `${baseUrl}/api/3/action/process_resources_download`,
  zippedResourcesDownload: `${baseUrl}/api/3/action/download_resources_processed?id=`,

  memberQuery: param => ({
    singleDataSet: `${baseUrl}/api/3/action/package_search?include_private=true&include_drafts=true&q=dataset_type:dataset&fq=name:${
      param.id
    }`,
    singleResource: `${baseUrl}/api/3/action/resource_show?id=${
      param.id
    }`,
    statePackage: `${baseUrl}/api/3/action/package_search?include_private=true&fq=organization:${
      param.name
    }`,
    sectorPackage: `${baseUrl}/api/3/action/package_search?include_private=true&fq=groups:${
      param.name
    }`,
    sectorActivities: `${baseUrl}/api/3/action/group_activity_list?id=${
      param.id
    }`,
    stateActivities: `${baseUrl}/api/3/action/organization_activity_list?id=${
      param.id
    }`,
    activityDetail: `${baseUrl}/api/3/action/package_activity_list?id=${
      param.id
    }`,
    // TODO - THE HOST IS BAD + THE Layer is bad ! Seems this url is not used at all ; commenting for now
    //geoserverBoundaries: `https://geoserver.grid-nigeria.org/geoserver/GRIDMaster/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=GRIDMaster:sv_boundary_states&CQL_FILTER=state_name=%27${
    // geoserverBoundaries: `http://subu07.ad.novel-t.ch:10001/geoserver/GRIDMaster/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=GRIDMaster:sv_boundary_states&CQL_FILTER=state_name=%27${
    // //geoserverBoundaries: `${process.env.REACT_APP_GEOSERVER_BASE_URL}/GRIDMaster/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=GRIDMaster:u_boundary_states&CQL_FILTER=state_name=%27${
    //   param.id
    // }%27&outputFormat=application%2Fjson&authKey=${geoserverKey}`
  }),
 // homeMap: `${baseUrl}/api/action/eoc_get_map_data?type=country`
};

export default urls;
