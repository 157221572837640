/**
 * @file - class based component for data set page.
 */

// Third Party imports
import React, { Component } from "react";
import { Redirect, NavLink, Link, Route, Switch, withRouter } from "react-router-dom";
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import saveAs from "file-saver";
import * as L from "leaflet";

// Local inports
import {Loader, Metadata, PageTemplate, ActivityStream} from "../common";
import { DatasetInfo } from "./components/DatasetInfo";
import { ResourcesList } from "./components/ResourcesList";
import { dispatchActions } from "../../store/actions/action-config.action";
import { MapObject } from "../../utils/map/map.object";
import { getData } from "../../api-requests/index";
import authzHelper from "../../utils/auth";
import urls from "../../utils/urls";


class DatasetDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedResources: []
    };
    this.metadataExcludeList = [
      'id', 'name', 'title', 'notes', 'private', 'type', 'url', 'resources',
      'organization', 'groups', 'tags', 'state', 'version', 'isopen'
    ];
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const proxyGetDataset = () => {
      return getData({
        url: urls.memberQuery({ id }).singleDataSet
      });
    };
    this.props.dispatchActions("GET_DATASET", {func: proxyGetDataset});

    const proxyGetActivities = () => {
      return getData({
        url: urls.memberQuery({ id }).activityDetail
      });
    }
    this.props.dispatchActions("GET_DATASET_ACTIVITIES", {func: proxyGetActivities});
  }

  toggleResourceSelection = (evt, resxId) => {
    const { selectedResources } = this.state;
    const checked = evt.currentTarget.checked;
    const idx = selectedResources.indexOf(resxId);
    if (checked && idx === -1) {
      selectedResources.push(resxId);
    } else if (!checked && idx !== -1) {
      selectedResources.splice(idx, 1);
    }
    this.setState({ selectedResources });
  }

  markAllHandler = (evt) => {
    const dataset = (
      this.props.datasetPayload && this.props.datasetPayload.result &&
      this.props.datasetPayload.result.results[0]
    ) || {};
    const resources = (dataset && dataset.resources) || [];
    const { selectedResources } = this.state;

    if (selectedResources.length === resources.length) {
      selectedResources.splice(0, selectedResources.length);
    } else {
      resources.forEach(resx => {
        if (selectedResources.indexOf(resx.id) === -1) {
          selectedResources.push(resx.id);
        }
      });
    }
    this.setState({ selectedResources });
  }

  downloadHandler = (evt) => {
    const dataset = (
      this.props.datasetPayload && this.props.datasetPayload.result &&
      this.props.datasetPayload.result.results[0]
    ) || {};
    const resources = (dataset && dataset.resources) || [];
    const { selectedResources } = this.state;
    const dloadList = [];

    resources.forEach(r => {
      if (selectedResources.indexOf(r.id) !== -1) {
        dloadList.push({id:r.id, format:r.format.toLowerCase()});
      }
    });

    getData({
      url: urls.prepareDownloads, context: 'POST',
      inputData: { resources: dloadList, include_metadata: true }
    }).then(resp => {
        if (resp.result && resp.result.file_id) {
          // download zip file by id
          window.location.href = `${urls.zippedResourcesDownload}${resp.result.file_id}`;
        } else {
          // Resource(s) were not archived
          toastr.warning('Download Error', 'Encountered an error archiving resources');
        }
      })
      .catch(err => {
        // show download error
        toastr.error('Download Error', `${err.toString()}`);
      })
  }

  render() {
    const { match, datasetPending, datasetPayload } = this.props;
    const dataset = (datasetPayload && datasetPayload.result && datasetPayload.result.results[0]) || {};
    const loaderStyle = { background: 'transparent', position: 'relative', height: '500px'};
    const userAuth = authzHelper(this.props.userDataPayload);

    return (
      <PageTemplate path="/datasets">
      { datasetPending
        ? <Loader show={true} customStyle={loaderStyle} />
        : (
        <React.Fragment>
          <div className="container-fluid">
            <div className="toolbar">
              <ol className="breadcrumb">
                <li>
                  <Link to="/"> <i className="fa fa-home"></i></Link>
                </li>
                <li>
                  <Link to="/datasets">Datasets</Link>
                </li>
                <li className="active">
                  {dataset.title}
                </li>
              </ol>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row-fluid">
              <div className="span10 offset1">
                <div className="dataset-container">
                  <h1 className="page-title">
                    {dataset.title}
                    {dataset.private &&
                      <span className="dataset-private label label-inverse">
                        <i className="fa fa-lock"></i> Private
                      </span>
                    }
                  </h1>

                  <div className="left">
                    <DatasetInfo {...dataset} />
                  </div>

                  <div className="right">
                    <div className="module">
                      <h2 className="module-heading">Description</h2>
                      <section className="module-content">
                        <p
                          dangerouslySetInnerHTML={{
                        __html: dataset.notes
                      }}></p>
                      </section>
                    </div>
                    { ! userAuth.canAccessDataset(dataset)
                      ? (
                        <a href={`/dataset/request_access/${dataset.name}?type=dataset`} className="btn btn-primary btn-large">
                          <i className="fa fa-unlock"></i> <span className="fa-label">Request access</span>
                        </a>
                      ): (
                        <div className="module">
                          <div className="module-content">
                            <ul className="nav nav-tabs">
                              <li>
                                <NavLink activeClassName="active" to={`${match.url}/resources`}>Data and Resources</NavLink>
                              </li>
                              <li>
                                <NavLink activeClassName="active" to={`${match.url}/metadata`}>Metadata</NavLink>
                              </li> {/*
                              <li className={match.path === "/map" ? "active" : ""}>
                                <NavLink activeClassName="active" to={`${match.url}/map`}>
                                  <i className="fas fa-globe-africa"></i> Map
                                </NavLink>
                              </li> */}
                              <li className={match.path === "/activity" ? "active" : ""}>
                                <NavLink activeClassName="active" to={`${match.url}/activity`}>
                                  <i className="far fa-clock"></i> Activity Stream
                                </NavLink>
                              </li>
                            </ul>

                            <React.Fragment>
                              <Switch>
                                <Redirect exact from={`${match.url}`} to={`${match.url}/resources`} />
                                <Route exact path={`${match.url}/resources`}
                                  render={routeProps => (
                                    <ResourcesList
                                      userAuth={userAuth}
                                      {...this.state} dataset={dataset}
                                      markAllHandler={this.markAllHandler}
                                      downloadHandler={this.downloadHandler}
                                      toggleSelection={this.toggleResourceSelection} />
                                  )} />
                                <Route exact path={`${match.url}/metadata`}
                                  render={routeProps => (
                                    <Metadata {...routeProps} {...this.props}
                                      excludeList={this.metadataExcludeList}
                                      dataObject={dataset}
                                      type='dataset'
                                      wrap={true} />
                                  )} />
                                <Route exact path={`${match.url}/activity`}
                                  render={routeProps => (
                                    this.props.activityPending === true
                                    ? <Loader show={true} customStyle={loaderStyle} />
                                    : <ActivityStream activities={
                                      (this.props.activityPayload && this.props.activityPayload.result) || []
                                    } />
                                  )} />
                                <Route exact path={`${match.path}/resource/:resId`}
                                  render={routeProps => (
                                    <Redirect to={`/resource/${routeProps.match.params.resId}?dataset=${match.params.id}`} />
                                  )} />
                              </Switch>
                            </React.Fragment>
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
        )
      }
      </PageTemplate>
    )
  }
}

const mapStateToProps = ({ setUserData, getDataset, getDatasetActivities }) => ({
  userDataPending: setUserData.pending,
  userDataPayload: setUserData.payload,
  userDataError: setUserData.error,

  datasetPending: getDataset.pending,
  datasetPayload: getDataset.payload,
  datasetError: getDataset.error,

  activityPending: getDatasetActivities.pending,
  activityPayload: getDatasetActivities.payload,
  activityError: getDatasetActivities.error
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatchActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DatasetDetail));
