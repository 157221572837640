/**
 * @file - define modals
 */
// Third party imports
import React from "react";
// Local imports
export const Modal = ({
  width,
  height,
  display,
  embedLink,
  resizeHandler,
  embedCopyHandler,
  closeModalHandler
}) => {
  const embed = `<iframe width="${width}" height="${height}" src="${embedLink}" frameBorder="0"></iframe> `;

  if (display) {
    return (
      <div className=" t-modal">
        <div className="t-body">
          <div className="t-main">
            <div className="t-row">
              <h2 className='t-modal-heading'>Embed resource view </h2>
              <i className="t-close fa fa-times" onClick={closeModalHandler} />
            </div>
            <div className="t-row">
              <span>
                You can copy and paste embedded code into a CMS or blog software
                that supports raw HTML.
              </span>
            </div>
            <div className="t-row">
              <div>
                <label>Width</label>
                <input type="number" value={width}
                  onChange={e => resizeHandler(e, "width")}
                />
              </div>
              <div>
                <label>Height</label>
                <input type="number" value={height}
                  onChange={e => resizeHandler(e, "height")}
                />
              </div>
            </div>
            <div className="t-row">
              <div>
                <label>Body</label>
                <textarea rows={5} cols={80} value={embed}>
                </textarea>
              </div>
            </div>
            <div className="t-row">
              <button className="btn btn-default" onClick={closeModalHandler}> Cancel</button>
              <button className="btn btn-primary" onClick={embedCopyHandler}>
                Copy code to clipboard
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return "";
  }
};
