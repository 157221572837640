import React from "react";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Local inports
import { dispatchActions } from "../../store/actions/action-config.action";
import { getData } from "../../api-requests/index";
import urls from "../../utils/urls";

// POST REQUESTS include_datasets id
import { PageTemplate, ActivityStream, Loader, DatasetGroup } from "../common";

const loaderStyle = { backgroundColor: 'transparent' }


class Sector extends DatasetGroup {

  constructor(props) {
    super(props);
    this.TYPE = "sector";
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    const proxyGetSector = () => {
      return getData({
        url: urls.unitSector, context: "POST",
        inputData: { id, include_datasets: true }
      });
    }
    this.props.dispatchActions("GET_SECTOR", { func: proxyGetSector });
    super.componentDidMount();
  }

  render() {
    const { groupPayload, groupPending } = this.props;
    const sector = (groupPayload && groupPayload.result) || {};

    return (
      <PageTemplate path="/sectors">
        {!groupPending ? (
          <React.Fragment>
            <div className="container-fluid">
              <div className="toolbar">
                <ol className="breadcrumb">
                  <li>
                    <Link to="/sectors">Sectors</Link>
                  </li>
                  <li className="active">{ sector.title }</li>
                </ol>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row-fluid">
                <div className="span10 offset1">
                  <div className="state-container">
                    <div className="left">
                      <div className="module">
                        <section className="module-content">
                          <div className="image center">
                            <Link to={`/sector/${sector.name}`}>
                              <img src={ sector.image_display_url } alt={ sector.title } />
                            </Link>
                          </div>
                        </section>
                      </div>

                      <div className="module">
                        <h2 className="module-heading">Followers</h2>
                        <section className="module-content">
                          <span className="number entry">{sector.num_followers}</span>
                        </section>
                      </div>
                    </div>

                    <div className="right">
                      <div className="module">
                        <h1 className="page-title">{ sector.title }</h1>
                        <section className="module-content">
                          <p>{ sector.description }</p>
                        </section>
                      </div>
                      <div className="module">
                        <div className="module-content">
                          <ul className="nav nav-tabs">
                            <li className={this.state.VIEW === "dataset" ? "active" : "" }>
                              <a onClick={() => this.setState({ VIEW: "dataset" }) }>
                                Datasets
                              </a>
                            </li>
                            <li className={this.state.VIEW === "activity" ? "active" : ""}>
                              <a onClick={() => this.setState({ VIEW: "activity" }) }>
                                Activity Stream
                              </a>
                            </li>
                          </ul>
                          <div className="tab-content">
                            { this.state.VIEW === "dataset"
                              ? ( this.DatasetList({
                                  title: sector.title, datasets: this.state.datasetList
                                }))
                              : ( <section className="tab-pane active">
                                  { this.props.activityPending === true
                                    ? <Loader show={true} customStyle={loaderStyle} />
                                    : <ActivityStream activities={this.props.activityPayload.result} />
                                  }
                                  </section>
                                )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="container-fluid">
              <div className="row-fluid">
                <div className="span10 offset1">
                  <div className="state-container">
                    <div className="page-preload">
                      <Loader show={true} customStyle={loaderStyle} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </PageTemplate>
    );
  }
}

const mapStateToProps = ({
  setUserData, getSector, getAllDatasets, getSectorActivities
}) => {
  return {
    userDataPending: setUserData.pending,
    userDataPayload: setUserData.payload,
    userDataError: setUserData.error,

    groupPending: getSector.pending,
    groupPayload: getSector.payload,
    groupError: getSector.error,

    datasetsPending: getAllDatasets.pending,
    datasetsPayload: getAllDatasets.payload,
    datasetsError: getAllDatasets.error,

    activityPending: getSectorActivities.pending,
    activityPayload: getSectorActivities.payload,
    activityError: getSectorActivities.error
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators({ dispatchActions }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sector));
