import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CheckBox from '../datasets/components/CheckBox'
import RadioButton from './RadioButton'

const geoFormats = [
  { value: '', label: 'as Original Content'},
  { value: 'csv', label: 'as CSV'},
  { value: 'gpkg', label: 'as GeoPackage'},
  { value: 'sqlite', label: 'as SQLite/SpatiaLite'},
  { value: 'gml', label: 'as Geography Markup Language (GML)'},
  { value: 'shp', label: 'as ESRI Shapefile'}
]

class DownloadBar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isDownloadOpen: false,
      formats: null,
      includeMetaData: true,
      selectedGoeFormats: null
    }
  }
  
  toggleDownload = () => {
    this.setState({ isDownloadOpen: !this.state.isDownloadOpen })
  }

  componentDidMount () {
    this.getFormats(this.props.formats)
  }

  componentWillReceiveProps (nextProps) {
    this.getFormats(nextProps.formats)
  }

  onMetaDataToggle = event => {
    this.setState({
      includeMetaData: event.target.checked
    })
  }

  onCheckBoxLoad = key => {
    const formatInDownloads =  this.props.downloads.find(x => x.format === key)
    if (formatInDownloads) {
      return true
    }
    return false
  }

  onGeoFormatChanged = geo => {
    this.setState({
      selectedGoeFormats: geo
    })
  }

  getFormats = (f) => {
    let formats = []
    if (f) {
      Object.keys(f).forEach(key => {
        if (key.toLowerCase() === 'geojson') {
          formats.push(
            <ul className='settings-section' key={key}>
              <li><label className='check-default'><CheckBox onChange={this.props.onFormatChanged.bind(this, key)} onLoad={this.onCheckBoxLoad.bind(this, key)} /><span>{key}</span></label></li>
              <li>
                <RadioButton default={geoFormats[0].value} options={geoFormats} name='format' onChange={this.onGeoFormatChanged}/>
              </li>
            </ul>
          )
        } else {
          formats.push(
            <ul className='settings-section' key={key}>
              <li><label className='check-default'><CheckBox onChange={this.props.onFormatChanged.bind(this, key)} onLoad={() => (true)} /><span>{key}</span></label></li>
            </ul>
          )
        }
      })
    }
    this.setState({
      formats
    })
  }

  download = () => {
    this.props.downloadResources({
      include_metadata: this.state.includeMetaData,
      geoJsonFormat: this.state.selectedGoeFormats
    })
  }

  render () {
    return(
      <div className={ this.state.isDownloadOpen ? 'download-section': 'download-section collapsed'}>
        <div className='btn btn-primary btn-block btn-large download-toggle' onClick={this.toggleDownload}>
          <span>{`${this.props.downloads.length} ${this.props.downloads.length > 1 ? 'Resources' : 'Resource'}`} selected</span>
          <div className='download-toggle-icon'></div>
        </div>
        <div className='download-settings'>
          {this.state.formats}
          <ul className='settings-section'>
            <li><label className='check-default'><input type='checkbox' onChange={this.onMetaDataToggle} checked={this.state.includeMetaData}></input><span>Include Metadata</span></label></li>
          </ul>
          <div className='download-summary'>
            <h3 className='title'>Summary</h3>
            <ul>
            {
              this.props.downloads.map(download => (
                <li key={download.id}>{`${download.datasetTitle} - ${download.name}`}<span className='label' data-format={download.format}>{download.format}</span></li>
              ))
            }
            </ul> 
          </div>
        </div>
        <div className='btn btn-primary btn-block btn-large download-button' onClick={this.download}>Download</div>
      </div>
    )
  }
}

DownloadBar.propTypes = {
  downloads: PropTypes.array.isRequired,
  downloadResources: PropTypes.func.isRequired
}

export default DownloadBar