import * as React from "react";
import { baseUrl } from "../../../utils/urls";
import * as utils from "../../../utils/string.utils";

export const DatasetInfo = ({organization, groups, tags, num_followers}) => {
  let state = organization || {};

  return (
    <React.Fragment>
      <div className="module">
        <h2 className="module-heading">State</h2>
        <section className="module-content box">
          <div className="image">
            <a href={`/state/${state.name}`}>
              <img
                src={`${baseUrl}/uploads/group/${state.image_url}`}
                alt={`${state.name}`}
              />
            </a>
          </div>

          <div className="info">
            <h2 className="heading">{state.title}</h2>
            <p>
              { utils.truncateString(state.description) }
            </p>
          </div>
        </section>
      </div>

      <div className="module">
        <h2 className="module-heading">Sector</h2>
        {
          (groups || []).map((group, index) => {
            return (
              <section key={index}  className="module-content box">
                <div className="image">
                  <a href={`/sector/${group.name}`}>
                    <img className="media-image" src={group.image_display_url} alt={group.name} />
                  </a>
                </div>

                <div className="info">
                  <h2 className="heading">{group.title}</h2>
                  <p>{ group.description }</p>
                </div>
              </section>
            )
          })
        }
      </div>

      <div className="module">
        <h2 className="module-heading">Tags</h2>
        <section className="module-content">
        {
          (tags || []).map((tag) => {
            return (
              <span key={tag.id} className="tag">{tag.display_name}</span>
            )
          })
        }
        </section>
      </div>

      <div className="module">
        <h2 className="module-heading">Followers</h2>
        <section className="module-content">
          <span className="number entry">{ num_followers || 0 }</span>
        </section>
      </div>
    </React.Fragment>
  )
};
