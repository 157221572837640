import React, { Component } from 'react'
import Select, { components } from 'react-select'

class MultiSelect extends Component {

  Option = props => (
      <components.Option {...props}>
      </components.Option>
  )

  render() {
    return (
      <Select
        {...this.props}
        className='grid3-select'
        classNamePrefix='select'
        components={{ Option: this.Option }}
      />
    )
  }
}

export default MultiSelect