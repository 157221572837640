import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom';

const RecentDatasets = ({ dataset }) => (
  <div className='teaser'>
  <Link className='teaser-heading' to={`/dataset/${dataset.name}`}>
  <h3 className='title-xl'>{dataset.title}</h3><i className='icon-linkto'></i>
  </Link>
    <p dangerouslySetInnerHTML={{
      __html: dataset.notes
    }}></p>
    <p><span className='text-light'>modified on </span>{moment(dataset.metadata_modified).format('DD MMMM, YYYY')}</p>
  </div>
)

export default RecentDatasets
