import React, { Component } from "react";
import Select from "react-select";
import PropTyes from "prop-types";

class SortSelect extends Component {
  options = [
    { value: "relevance%20asc", label: "Relevance" },
    { value: "name%20asc", label: "Name Ascending" },
    { value: "name%20desc", label: "Name Descending" },
    { value: "metadata_modified%20desc", label: "Last Modified" }
  ];

  render() {
    const options = this.props.options ? this.props.options : this.options;
    return (
      <Select
        {...this.props}
        defaultValue={options[this.props.defaultIndex || 0]}
        className="grid3-select grid3-select-plain"
        classNamePrefix="select"
        options={options}
        onChange={this.props.onChange}
        isSearchable={false}
      />
    );
  }
}

SortSelect.propTypes = {
  defaultIndex: PropTyes.number,
  onChange: PropTyes.func.isRequired,
  value: PropTyes.object.isRequired,
  onChange: PropTyes.func
};

export default SortSelect;
