import * as L from 'leaflet'
import 'leaflet.markercluster'
import { genHeatMap } from './map/mapfunctions'

let currentState = null
let prevColor = null

const colors = {
	primary_pale: '#1D8449',
	primary: '#67D699',
	white: '#fff',
  web_green: '#1C6438'
}

// Define basic state style
const defaultState = {
	weight: 1,
	opacity: 1,
  color: colors.white,
  fillOpacity: 1
}

const loadGeojson = (data, element, cb, facets) => {
  let facetsTotal = 0
  const keys = Object.keys(facets)
  let max = 0
  keys.forEach(facet => {
    facetsTotal = facetsTotal + facets[facet]
  })
  keys.forEach(facet => {
    facets[facet] = (facets[facet] / facetsTotal) * 100
    if (facets[facet] > max){
      max = facets[facet]
    }
  })
  const normalizer = 100 - max
  keys.forEach(facet => {
    facets[facet] = normalizer + facets[facet]
  })
  const geojson = L.geoJson(data.features, {
    style: defaultState,
    onEachFeature: onEachFeature.bind(this, cb, facets)
  }).addTo(element)
  const bounds = geojson.getBounds()

  element.fitBounds(bounds)
  return geojson
}

const loadGeojsonInClusters = (data, clusterGroup, map) => {
  const geojson = L.geoJson(data.features, {
    style: {...defaultState, 'fillColor': colors.web_green},
    onEachFeature: onEachFeature.bind(this, () => {}, {})
  })
  clusterGroup.addLayer(geojson)
  const bounds = geojson.getBounds()
  try {
    map.fitBounds(bounds)
  } catch {
    // todo: handle invalid bounds
  }
  return geojson
}

const initMap = (element, options, tileLayer=true) => {
  const map = L.map(element, options)
  if (tileLayer) {
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map)
  }
  return map
}

const addMarker = (map, point, popup) => {
  L.marker(point).addTo(map)
    .bindPopup(popup)
    .openPopup()
}

const zoomControls = (element, position) => {
  const zoomControl = L.control.zoom({
    position
  })
  zoomControl.addTo(element)
}

// Get popup content for layer
const getTooltipContent = e => {
  const layer = e.target
  return layer.feature.properties.name || layer.feature.properties.ward_name || layer.feature.properties.lga_name || layer.feature.properties.state_name || 'Unknown'
}

// Bind popup and open it
const addTooltip = e => {
  const layer = e.target
  layer.bindTooltip(getTooltipContent(e), {
    direction: 'top'
  }).openTooltip()
}

// Unbind popup and close it
const removeTooltip = e => {
  const layer = e.target
  layer.unbindTooltip(getTooltipContent(e)).closeTooltip()
}

// Apply highlight location style
const setHighlight = e => {
  const hoverLocation = {
    fillOpacity: 1,
    weight: 1,
    fillColor: colors.primary
  }
  const layer = e.target

  if (layer.setStyle)
    layer.setStyle(hoverLocation)

  if (!L.Browser.ie && !L.Browser.opera && layer.bringToFront) {
    layer.bringToFront()
  }
}

// Revert highlight style changes
const resetHighlight = (color, e) =>  {
  const layer = e.target
  if (currentState) {
    if (currentState.feature.properties.code.trim() === layer.feature.properties.code.trim())
      return
  }
  if (layer.setStyle)
    layer.setStyle({fillColor: color, weight: 1})
}

const onPolygonClick = (cb, color, e) => {
  const prevState = currentState
  if (!prevColor) {
    prevColor = color
  }
  currentState = e.target
  if (prevState) {
    if (prevState.feature.properties.code.trim() !== currentState.feature.properties.code.trim()) {
      prevState.setStyle({fillColor: prevColor, weight: 1})
      setHighlight({target: currentState})
    }
  } else {
    setHighlight({target: currentState})
  }
  prevColor = color
  const properties = e.target.feature.properties
  cb(properties.name.trim())
}

// Set Nigeria map polygons
const onEachFeature = (cb, facets, feature, layer) => {
  let location = layer.feature.properties.name || layer.feature.properties.ward_name || layer.feature.properties.lga_name || layer.feature.properties.state_name || 'Unknown'
  location = location.toLowerCase().trim()
  const datasetCount = facets[location] || 0
  const initalColor = genHeatMap(datasetCount, colors.web_green)
  if (layer.setStyle) {
    layer.setStyle({ fillColor: initalColor })
  }
  layer.on({
    tooltipopen: setHighlight,
    tooltipclose: resetHighlight.bind(this, initalColor),
    click: layer.feature.properties.name ? onPolygonClick.bind(this, cb, initalColor) : null,
    mouseover: addTooltip,
    mouseout: removeTooltip
  })
}

const removeAllLayers = (map) => {
  map.eachLayer(layer => {
    map.removeLayer(layer)
  })
}

const getMarkerClusterGroup = () => (
  L.markerClusterGroup()
)

export {
  loadGeojson,
  initMap,
  addMarker,
  zoomControls,
  removeAllLayers,
  getMarkerClusterGroup,
  loadGeojsonInClusters
}
