import React, { Component } from 'react'
import { connect } from 'react-redux'

import PageTemplate from '../common/PageTemplate'

class Partner extends Component {
  render () {
    return(
      <PageTemplate path={this.props.match.path}>
        <div className='container-fluid'>
          <div className='image-header'>
            <div className='bg-image'>
              <img src='/image/about-bg.jpg' alt='nigeria'></img>
            </div>
            <h2 className='headline-xxl text-center'>GRID<sup>3</sup> Partners</h2>
          </div>
          <div className='row-fluid'>
            <div className='span10 offset1'>
              <div className='module'>
                <div className='module-content'>
                  <p className='headline-xl'>
                    Local Partner
                  </p>
                </div>
                <div className='module-content flex partner'>
                  <div className='image center'>
                    <img src='/image/eHA-logo.png' alt='eHealth Africa Logo'></img>
                  </div>
                  <div className='text-m flex2'>
                    <h3 className="title-l">eHealth Africa</h3>
                    <p>The GRID<sup>3</sup> Nigeria project works across all states in Nigeria to collect accurate, complete, and geospatially referenced data relevant to a variety of sectors. Over the course of the project <b>over 500,000 points of interest</b> are collected and <b>over 2,000 data collectors</b> are being trained in the use of mobile data collection technology.</p>
                    <a href='http://ehealthafrica.org' target='blank'>Visit eHealth Africa</a>
                  </div>
                </div>
              </div>
              <div className='module'>
                <div className='module-content'>
                  <p className='headline-xl'>
                    Global Implementing Partners
                  </p>
                </div>
                <div className='module-content flex partner'>
                  <div className='image center'>
                    <img src='/image/flowminder-logo.png' alt='Flowminder-logo'></img>
                  </div>
                  <div className='text-m flex2'>
                    <h3 className="title-l">WorldPop/Flowminder</h3>
                    <p>provides advanced spatial analytics, micro-census survey design, population estimation modelling, and capacity strengthening.</p>
                    <a href='http://www.worldpop.org.uk/' target='blank'>Visit WorldPop</a> | <a href='http://www.flowminder.org/' target='blank'>Visit Flowminder</a>
                  </div>
                </div>
                <div className='module-content flex partner'>
                  <div className='image center'>
                    <img src='/image/unfpa-logo.png' alt='Unfpa-logo'></img>
                  </div>
                  <div className='text-m flex2'>
                    <h3 className="title-l">United Nations Population Fund (UNFPA)</h3>
                    <p>provides advocacy and coordination with national governments, technical support for census activities, micro-census and survey coordination and implementation, and capacity strengthening.</p>
                    <a href='https://www.unfpa.org/' target='blank'>Visit UNFPA</a>
                  </div>
                </div>
              </div>

              <div className='module'>
                <div className='module-content'>
                  <p className='headline-xl'>
                    Global Coordinating Partner
                  </p>
                </div>
                <div className='module-content flex partner'>
                  <div className='image center'>
                    <img src='/image/ciesin-logo.png' alt='Ciesin-logo'></img>
                  </div>
                  <div className='text-m flex2'>
                    <h3 className="title-l">Center for International Earth Science Information Network (CIESIN)</h3>
                    <p>provides project management and coordination, technical support for data policies, institutions, and platforms, and builds connections to global networks.</p>
                    <a href='http://www.ciesin.org/' target='blank'>Visit CIESIN</a>
                  </div>
                </div>
              </div>

              <div className='module'>
                <div className='module-content'>
                  <p className='headline-xl'>
                    Funders
                  </p>
                </div>
                <div className='module-content flex partner'>
                  <div className='image center'>
                    <img src='/image/bmgf-logo.png' alt='bmgf-logo'></img>
                  </div>
                  <div className='text-m flex2'>
                    <h3 className="title-l">Bill & Melinda Gates Foundation</h3>
                    <p>work with partners worldwide to tackle critical problems.</p>
                    <a href='https://www.gatesfoundation.org/' target='blank'>Visit Bill & Melinda Gates Foundation</a>
                  </div>
                </div>
                <div className='module-content flex partner'>
                  <div className='image center'>
                    <img src='/image/ukaid-logo.png' alt='UK aid-logo'></img>
                  </div>
                  <div className='text-m flex2'>
                    <h3 className="title-l">UK Department for International Development</h3>
                    <p>The Department for International Development (DFID) leads the UK’s work to end extreme poverty. We are tackling the global challenges of our time including poverty and disease, mass migration, insecurity and conflict. Our work is building a safer, healthier, more prosperous world for people in developing countries and in the UK too.</p>
                    <a href='https://www.gov.uk/government/organisations/department-for-international-development' target='blank'>Visit DFID</a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </PageTemplate>
    )
  }
}

export default connect()(Partner)