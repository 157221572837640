import React from 'react'
import PropTypes from 'prop-types'

import { Header, Footer } from './index'

const PageTemplate = props =>  {
    return(
      <div>
        <Header {...props} />
        {props.children}
        <Footer />
      </div>
    )
}

PageTemplate.propTypes = {
  isHome: PropTypes.bool,
  path: PropTypes.string.isRequired
}

export default PageTemplate
