import React, { Component } from 'react'
import PropTypes from 'prop-types'


class RadioButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedOption: ''
    }
  }

  componentDidMount () {
    if (this.props.default) {
      this.setState({
        selectedOption: this.props.default
      })
    }
  }

  onChange = e => {
    this.setState({
      selectedOption: e.target.value
    })
    this.props.onChange(e.target.value)
  }

  render () {
    const options = this.props.options || []
    return (
      <ul>
        {
          options.map(option => (
            <li key={option.value}>
              <label className='radio-default'>
                <input
                type='radio'
                name={this.props.name}
                value={option.value}
                onChange={this.onChange}
                checked={this.state.selectedOption === option.value}
                >
                </input><span>{option.label}</span>
              </label>
            </li>
          ))
        }
      </ul>
    )
  }
}

RadioButton.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired
}

export default RadioButton
