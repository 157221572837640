/*eslint:disable*/
/**
 * @file Logger util to log information, warnings and errors.
 */

// TODO (@team): We need to implement checks before logging in different environment.

const Log = {
    info: (logMessage) => {
        if (process.env.NODE_ENV !== 'production') {
            console.log(logMessage);
        }
    },
    error: (logMessage) => {
        console.error(logMessage);
    },
    warn: (logMessage) => {
        if (process.env.NODE_ENV !== 'production') {
            console.warn(logMessage);
        }
    }
};

export default Log;